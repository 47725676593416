import React from 'react'
import MUIDataTable from "mui-datatables";
import moment from "moment";

const LogsTable = ({ logsData }) => {

  const dataArray = logsData
  // const columns = ["Time", "Module", "User", "Description"];

  const columns = [
    {
        name: "Time",
        label: "Time",
        options: {
          customHeadRender: ({index, ...column}) => {
              return (
                <th
                key={index}
                style={{
                  backgroundColor: "#ebf0fa",
                  color: "black",
                  fontWeight: "bold",
                  textAlign: 'left',
                  padding: 20
                }}
              >
               {column.label}
              </th>
              )
          }
      }
    },
    {
        name: "Module",
        label: "Module",
        options: {
          customHeadRender: ({index, ...column}) => {
              return (
                <th
                key={index}
                style={{
                  backgroundColor: "#ebf0fa",
                  color: "black",
                  paddingLeft: 15,
                  textAlign: 'left'
                }}
              >
               {column.label}
              </th>
              )
          }
      }
    },
    {
        name: "User",
        label: "User",
        options: {
          customHeadRender: ({index, ...column}) => {
              return (
                <th
                key={index}
                style={{
                  backgroundColor: "#ebf0fa",
                  color: "black",
                  paddingLeft: 15,   
                  textAlign: 'left'
                }}
              >
               {column.label}
              </th>
              )
          }
      }
    },
    {
      name: "Description",
      label: "Description",
      options: {
        customHeadRender: ({index, ...column}) => {
            return (
              <th
              key={index}
              style={{
                backgroundColor: "#ebf0fa",
                color: "black",
                paddingLeft: 16,
                textAlign: 'left'
              }}
            >
             {column.label}
            </th>
            )
        }
    }
   },
  ] 

  const transformLog = (log) => {
    return [
      moment(log.timestamp).format("MMMM Do YYYY, h:mm:ss"),
      `${log.module}`,
      log.userName,
      `${log.changes[0]}`,
    ]
  }

  const transaformData = (data) => {

    let resultantArr = []
    data.forEach(item => {
      let logsArray = transformLog(item)
      resultantArr.push(logsArray)
    });
    return resultantArr
  }
  const formattedLogs = transaformData(dataArray)

  const options = {
    rowsPerPageOptions: [5, 10, 20, 50, 100],
    print: false,
    download: false,
    searchOpen: true,
    viewColumns: false,
    rowsPerPage: 50,
    searchPlaceholder: "Search",
    jumpToPage: true,
    selectableRows: false,
  }

  return (
    <>
      <MUIDataTable
        data={formattedLogs.length > 0 ? formattedLogs : []}
        columns={columns}
        options={options}
      />
    </>

  )
}

export default LogsTable

