import React, { useState, useEffect } from 'react'
import { Button , InputGroup , FormControl} from 'react-bootstrap'
import { toast } from 'react-toastify'

import { transactionServices } from '../../../services/transaction'
import SearchModal from '../TransactionStatus/SearchModal'
// import { FieldGroup } from '../../FormInputs/FormInputs'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { getGiftCard } from '../../../redux/actions/giftCard-action'
// import { getUser } from '../../../redux/actions/user-actions'


const TMDashboard = () => {
  const [state, setState] = useState({
    value: '',
    searchModal: false,
    searchData: null,
  })
  const startDateDefault = moment()
    .subtract(1, 'year')
    .format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(startDateDefault)
  const today = moment().format('YYYY-MM-DD')
  const [endDate, setEndDate] = useState(today)
  const [totalAmount, setTotalAmount] = useState(0)
  const dispatch = useDispatch(dispatch);

  useEffect(async () => {
    const sum = await transactionServices.sumOfTransactions()
    if (sum) {
      setTotalAmount(sum)
    }
  }, [])

  const UserData = JSON.parse( localStorage.getItem("user"))
  let wareHouseUserFlag = UserData && UserData.user.status === 1

  const getSum =async ()=>{
    //console.log('get sum function')
    const sum = await transactionServices.sumOfTransactions()
    if (sum) {
      setTotalAmount(sum)
    }
  }

  useEffect(()=> {
    dispatch(getGiftCard(null))
  },[])

  useEffect(()=>{
    if(state.searchModal===false && state.value!=='' && state.searchData!==null){
      //console.log('inside if','state',state)
      getSum()
      setState({
        value: '',
        searchModal: false,
        searchData: null,
      })
      document.getElementById('search').focus()
    }
  },[state])

  const searchHandler = async (value) => {
    //console.log('value',value)
    const data = await transactionServices.searchByOrderNoOrTrackingNo(value)

    if (data.length !== 0) {
      setState({ ...state, searchData: data, searchModal: true })
    } else {
      toast.error('Transaction Not Found With this Id')
    }
  }

  const handleKeyDown = (e) => {
    // if (e.key === 'Enter' && state.value)
    // adding check for scanning tracking number and order no.
    if (e.key === 'Enter' && state.value && state.searchModal === false) {
      searchHandler(state.value)
    }
  }

  return (
    <div style={{ flexGrow: 1 }}>
      <div
        style={{
          paddingTop: '50px',
          paddingBottom: '40px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        <div className='row'>
          <div className='col-lg-6 col-sm-6'>
            { wareHouseUserFlag ? '':
              <div className='secHeading'>
               <i className='fa fa-exchange' aria-hidden='true'></i>
              <span className="gc_icon"> Dashboard </span> 
              </div>
            }
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: '50px',
          paddingBottom: '40px',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {state.searchModal && state.searchData && (
          <SearchModal
            open={state.searchModal}
            setOpen={() => {
              setState({
                ...state,
                searchModal: !state.searchModal,
              })
            }}
            searchData={state.searchData}
          />
        )}
        { wareHouseUserFlag &&
          <p style={{ fontFamily: 'Circular-Loom', fontSize: '20px', color: '#2f4576' }}>
            You can search transactions by Order Number or by Tracking Number.
          </p>
        }
        <div className='row'>
          <div className='col-sm-4'>
            <div
              style={{
                width: wareHouseUserFlag ? 'fit-content' : '',
                border: '1px solid #2f4576',
                paddingTop: wareHouseUserFlag ? '25px': '30px',
                paddingBottom: wareHouseUserFlag ? '25px': '30px',
                paddingLeft: wareHouseUserFlag ? '25px': '5px',
                paddingRight: wareHouseUserFlag ? '25px': '5px',
                borderRadius: '8px'
              }}
              align='middle'
            >
              <div className='input-group' style={{ width: wareHouseUserFlag ? "600px" :'300px' }}>
                <InputGroup>
                  <FormControl
                    id='search'
                    type="text"
                    autoFocus
                    placeholder='Search...'
                    value={state.value}
                    onChange={(e) =>
                      setState({ ...state, value: e.target.value })
                    }
                    onKeyDown={handleKeyDown} />
                  <InputGroup.Addon style={{ border: "1px solid #cccccc", cursor: 'pointer' }}
                    onClick={() => {
                      if (state.value) {
                        searchHandler(state.value)
                      }
                    }}
                  >
                    <i className='fa fa-search' />
                  </InputGroup.Addon>
                </InputGroup>
                {/* <input
                  style={{ border: '1px solid #E3E3E3', cursor: state.searchModal === true ? 'none' : ''}}
                  id='search'
                  className='form-control'
                  autoFocus
                  placeholder='Search...'
                  value={state.value}
                  onChange={(e) =>
                    setState({ ...state, value: e.target.value })
                  }
                  onKeyDown={handleKeyDown}
                /> */}
                {/* <div className='input-group-btn'>
                  <Button
                    style={{ marginLeft: 5 ,height: wareHouseUserFlag ? '40px': '40px'}}
                    onClick={() => {
                      if (state.value) {
                        searchHandler(state.value)
                      }
                    }}
                  >
                    <i className='fa fa-search'></i>
                  </Button>
                </div> */}
              </div>
            </div>
          </div>
           { wareHouseUserFlag ? '' :
           <div className='col-sm-4' style={{ marginLeft: '150px' }}>
            <div
              style={{
                width: '300px',
                border: '0px solid #c8cdc6',
                paddingTop: '20px',
                paddingBottom: '12px',
                backgroundColor: '#2f4576',
                borderRadius: 10,
              }}
              align='middle'
            >
              <div style={{ position: 'absolute', top: -40 }}>
                <p
                  style={{
                    width:'220px',
                    marginLeft: '50px',
                    fontSize: '16px',
                    color: '#2f4576',
                  }}
                >
                  Unreceived Inventory Value($)
                </p>
              </div>
              <p style={{ fontSize: '40px', color: 'white' }}>
                <i className='fa fa-usd'></i> {totalAmount.toLocaleString()}
              </p>
            </div>
          </div>
          }
        </div>
      </div>
    </div>
  )
}

export default TMDashboard
