import React from 'react'
import Pagination from '../ThemeComponents/Pagination'
import ShowRefundItems from './TransactionStatus/ShowRefundItems'
import {
  // Grid,
  // Row,
  Col,
  // Button,
  // Dropdown,
  DropdownButton,
  MenuItem,
} from "react-bootstrap";
import Select from "react-select";
import { FieldGroup as FieldGroupTwo } from "../FormInputs/FormInputs";


const TrasactionTable = ({
  pagination,
  columns,
  paginationCall,
  row,
  inventories,
  emails,
  addresses,
  toggleExpanded,
  setExpended,
  setExpended1,
  setRefreshEverythingElseData,
  detectChanges,
  setSelectedIds,
  ids,
	expanded,
	expandChange,
  startDate,
  banks,
  today,
  startDateDefault,
  handleChangeDropdown,
  selectedBank,
  showItems,
  setStartDate,
  endDate,
  setEndDate,
  filterBy,
  bankAccounts,
  selectedAccount,
  options,
  title2,
  exportToCSV,
  onChangeMultipleUnmark,
  markAsReceived,
	page
}) => {
  return (
    <div className='panel-body'>
      <Pagination
        style={{
          fontSize: '14px',
          textAlign: 'left',
          alignItems: 'left',
          alignContent: 'left'
        }}
        ref={pagination}
        filterBy={filterBy}
        showPagination={true}
        columns={columns}
        pageSize={50}
        noDataText='No Item found'
        getDataCall={paginationCall}
        filterPlaceHolder=''
        filterViewForFilter={true}
        selectMulti={true}
        multiSelector={'id'}
        showTotal={false}
				page={page}
        SubComponent={row => {
          if (row.original.transaction_type.name === 'refund') {
            return (
              <ShowRefundItems
                row={row}
                check={'unmarked'}
                inventories={inventories}
                emails={emails}
                address={addresses}
                toggleExpanded={toggleExpanded}
                setExpended={setExpended}
                setExpended1={setExpended1}
                setRefreshEverythingElseData={setRefreshEverythingElseData}
              />
            )
          }
          return showItems(row, 'unmark')
        }}
        onExpandedChange={(newExpanded, index, event, cellinfo) => {
          // setOrder(null);
          // setVendor(null);
          // setShipment([]);
          expandChange(newExpanded, index, event, cellinfo, 'unmarked')
        }}
        expanded={expanded}
        detectChanges={detectChanges}
        detectChangesAttr={'checkedIds'}
        detectChangesFlag={'pagination'}
        selectedIds={setSelectedIds}
        ids={ids}
        upperContent={
          <React.Fragment>
            <Col>
              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                style={{ width: filterBy === 'show_all' ? `15%` : null }}
              >
                <FieldGroupTwo
                  id='startDate'
                  type='date'
                  label='Start date'
                  name='startDate'
                  value={startDate}
                  className='fieldGroup'
                  // style={{width: filterBy === 'show_all' ? `50` : null}}
                  onChange={e => {
                    const d = e.target.value
                    if (!d.trim()) setStartDate(startDateDefault)
                    else setStartDate(d)
                  }}
                  max={endDate}
                />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                style={{ width: filterBy === 'show_all' ? `15%` : null }}
              >
                <FieldGroupTwo
                  id='endDate'
                  type='date'
                  label='End date'
                  name='endDate'
                  value={endDate}
                  className='fieldGroup'
                  onChange={e => {
                    const d = e.target.value
                    if (!d.trim()) setEndDate(today)
                    else setEndDate(d)
                  }}
                  max={today}
                />
              </Col>

              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                style={{ width: filterBy === 'show_all' ? `14%` : null }}
              >
                <div className='form-group'>
                  <label htmlFor='email'>Select Bank</label>
                  <Select
                    getOptionLabel={option => option.bank_name}
                    getOptionValue={option => option.access_token}
                    value={selectedBank ? selectedBank : null}
                    options={banks}
                    onChange={handleChangeDropdown}
                    placeholder='Select Bank'
                    className='new-font-size'
                    name='selectedBank'
                    isClearable={true}
                  />
                </div>
              </Col>

              <Col
                xs={12}
                sm={12}
                md={2}
                lg={2}
                style={{ width: filterBy === 'show_all' ? `14%` : null }}
              >
                <div className='form-group'>
                  <label htmlFor='email'>Select Account</label>
                  <Select
                    getOptionLabel={option =>
                      `${option.label ? option.official_name : ''} ${
                        option.mask
                      }`
                    }
                    getOptionValue={option => option.id}
                    className='new-font-size'
                    style={{ borderRadius: '5px' }}
                    options={bankAccounts}
                    onChange={handleChangeDropdown}
                    value={selectedAccount ? selectedAccount : null}
                    placeholder='Select Bank Account'
                    name='selectedAccount'
                    isClearable={true}
                  />
                </div>
              </Col>
              {filterBy === 'show_all' ? (
                <Col
                  xs={12}
                  sm={12}
                  md={2}
                  lg={2}
                  style={{ width: filterBy === 'show_all' ? `14%` : null }}
                >
                  <div className='form-group'>
                    <label htmlFor='email'>Transaction </label>

                    <Select
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.id}
                      isOptionDisabled={option => option.id === 7}
                      className='new-font-size'
                      style={{ borderRadius: '5px' }}
                      options={options.filter((opt) => (opt.label !== "All" && opt.label !== 'Add New Account'))}
                      onChange={handleChangeDropdown}
                      placeholder='Select Account'
                      name='transactionAccount'
                      isClearable={true}
                    />
                  </div>
                </Col>
              ) : null}

              <Col md={1} lg={1} sm={12} xs={12}>
                <div style={{ marginTop: '25px' }}>
                  <button
                    type='submit'
                    className='btn btn-lg srchBtn'
                    onClick={e => {
                      pagination.current.dataCall(1)
                    }}
                  >
                    {/* <span className="fa fa-search"></span>{" "} */}
                    Search
                  </button>
                </div>
              </Col>
            </Col>
            {pagination.current &&
            pagination.current.state.checkedIds.length > 0 ? (
              <Col md={12}>
                <ul
                  style={{
                    listStyle: 'none',
                    dispaly: 'flex',
                    padding: 0,
                    marginBottom: '1px'
                  }}
                >
                  <li style={{ marginTop: '24px', marginBottom: '-17px' }}>
                    <DropdownButton
                      // bsStyle="selectType"
                      title={title2}
                      className='selectType'
                      style={{ fontWeight: '500', padding: '12px 21px' }}
                      //  id="dropdown-basic-Default`"
                    >
                      <MenuItem
                        eventKey='1'
                        onClick={() => {
                          // setTitle2("Export transaction ")
                          exportToCSV('unmarked')
                        }}
                      >
                        Export transaction
                      </MenuItem>

                      <MenuItem
                        eventKey='2'
                        className='submenu'
                        onClick={() => {
                          //  setTitle2("Select Account ")
                        }}
                      >
                        Select Account
                        <span className='fa fa-caret-right'></span>
                        <MenuItem>
                          <ul role='menu' className='dropdown-menu subitems'>
                            {options &&
                             options.filter((opt) => (opt.label !== 'Add New Account')).map(option => (
                                <li
                                  role='presentation'
                                  key={option.id}
                                  onClick={() =>
                                    onChangeMultipleUnmark(option.id)
                                  }
                                >
                                  <a role='menuitem'> {option.label} </a>
                                </li>
                              ))}
                          </ul>
                        </MenuItem>
                      </MenuItem>

                        {filterBy === 'open' && (
                          <>
                            <MenuItem
                              eventKey='3'
                              disabled={filterBy === 'open' ? false : true}
                              onClick={() => {
                                markAsReceived()
                              }}
                            >
                              Mark As received in full
                            </MenuItem>
                          </>
                        )}
                     
                    </DropdownButton>
                  </li>
                </ul>
              </Col>
            ) : null}
          </React.Fragment>
        }
      />
    </div>
  )
}

export default TrasactionTable
