import React, { useState, useEffect, useRef } from 'react'
import Select from 'react-select'
import { useDispatch } from 'react-redux'
import { PlaidLink } from 'react-plaid-link'
import moment from 'moment'
import { Grid, Row, Col, Button,DropdownButton, Tooltip, OverlayTrigger,
  MenuItem, } from 'react-bootstrap'
import XLSX from 'sheetjs-style'
import Modal from 'react-bootstrap-modal'
import cloneDeep from 'lodash.clonedeep'

import { Card } from 'components/Card/Card.jsx'
import Pagination from 'components/ThemeComponents/Pagination'
import { plaidServices } from 'services/plaid'
import { transactionServices } from '../../../services/transaction'
import {
  objectsConstants as OC,
  shipmentObj,
  shipmentObject,
  shipmentObjectItems,
} from 'redux/constant/objects-constant'
import { exportData } from './exportData'
import { formatData } from './formatData'
import { alertActions } from 'redux/actions/alert-actions'
import { returnColumnValueWithColumnMatch } from 'helper/helperFunctions'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Accordion from './Accordion'
import styles from '../../Admin/TransactionStatus/searchModalStyles'
import "./styles.css"
import ImageViewerModal from '../../Admin/TransactionStatus/imageViewerModal'

import { emailServices } from '../../../services/email'
import { addresServices } from '../../../services/address'

import {
  ORDER_STATUSES,
  CARRIERS,
  ORDER_SUB_STATUSES,
} from '../../../constants'
import { FieldGroup as FieldGroupTwo } from '../../FormInputs/FormInputs'

import DataInput from './components/DataInput'
import FieldGroup from './components/FieldGroup'

import ShowRefundItems from './ShowRefundItems'

const startDateDefault = moment()
  .subtract(10, 'day')
  .format('YYYY-MM-DD')

function TransactionStatusList(props) {
  const dispatch = useDispatch()
  const [options, setOptions] = useState()
  const [transactionDetail, setTransactionDetail] = useState()
  const [state, setState] = useState({
    cardLink: null,
    publicTokenObject: null,
    accessToken: localStorage.getItem('accessToken'),
    banks: {},
    bankCollapse: false,
    newBankAccounts: [],
    accountModal: { show: false, bank_id: null },
    accountDateModal: { ...OC.ACCOUNTDATE },
    selectedNewBankAccounts: [],
    publicTokenObjectUpdated: false,
  })
  const [publicTokenObject, setPublicTokenObject] = useState(null)
  const [openToogle,setOpenToogle]=useState(false);
  const [transactionDetailOpen, setTransactionDetailOpen] = useState()
  const [expanded, setExpended] = useState({ 0: false })
  const [expanded1, setExpended1] = useState({ 0: false })
  const [title2,setTitle2] = useState('Actions');
  const [all, setAll] = useState(false,);
  const [evertThingElse, setEvertThingElse] = useState(true);
  const [remaining, setRemaining] = useState(false);
  const [showUnmarked, setShowUnmarked] = useState(true)
  const [showEverythingElse, setShowEverythingElse] = useState(true)
  const [paginationBulkAction, setPaginationBulkAction] = useState(false)
  const [paginationOpenBulkAction, setPaginationOpenBulkAction] = useState(
    false
  )
  const [bankAccounts, setBankAccounts] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [selectedAccount, setSelectedAccount] = useState(null)

  const [bankAccountsTwo, setBankAccountsTwo] = useState([])
  const [selectedBankTwo, setSelectedBankTwo] = useState({})
  const [selectedAccountTwo, setSelectedAccountTwo] = useState({})

  const [inventories, setInventories] = useState([])
  const [vendor, setVendor] = useState(null)
  const [order, setOrder] = useState(null)
  const [currentTran, setCurrentTran] = useState({})
  const [data, setData] = useState([])
  const [hasNew, setHasNew] = useState(false)
  const [dataModal, setDataModal] = useState(false)
  const today = moment().format('YYYY-MM-DD')
  const [startDate, setStartDate] = useState(startDateDefault)
  const [endDate, setEndDate] = useState(today)
  const [startDateTwo, setStartDateTwo] = useState(startDateDefault)
  const [endDateTwo, setEndDateTwo] = useState(today)
  const [filterBy, setFilterBy] = useState(null)
  const [tranStates, setTranStates] = useState([])
  const [refreshEverythingElseData, setRefreshEverythingElseData] = useState(
    false
  )
  const [checkOrderStatus, setCheckOrderStatus] = useState(false)
  const [shipment, setShipment] = useState([])
  const [oldShipment, setOldShipment] = useState([])
  const [isNewShipment, setIsNewShipment] = useState(false)

  const [showImageViewer, setShowImageViewer] = useState(false)
  const [imageUploaderData, setImageUploaderData] = useState({
    DataId: '',
    ItemId: '',
    ShipmentId: '',
  })

 const [showDetailModal , setShowDetailModal] = useState(false)
 const [transactionData, setTransactionData] = useState([])

 const [emails , setEmails] = useState([])
 const [addresses , setAddresses] = useState([])
 const [emailId , setEmailId] = useState(null)

 const [submitted, setSubmitted] = useState(false)

  //pagination refs
  const pagination = useRef()
  const paginationOpen = useRef()

  // const getTransStates = async () => {
  //   try {
  //     const response = await transactionServices.getTransactionStates()
  //     const transactionStates = response.data.filter((transactionState) => {
  //       return transactionState.name.toLowerCase() !== 'unmarked'
  //     })
  //     setTranStates([...transactionStates])
  //   } catch (error) {
  //     setTranStates([])
  //   }
  // }

  // useEffect(() => {

  //   getTransStates()
  // }, [])

  useEffect(() => {
    dispatch(getTransactionTypes())
    getInventoriesAsyncFunction()
    getAllEmails()
    getAllAddressData()
  }, [])

  useEffect(() => {
    if (showUnmarked) {
      function collapseCordian() {
        setExpended({ 0: false })
      }
      document
        .querySelector('.-previous')
        .addEventListener('click', collapseCordian)
      document
        .querySelector('.-next')
        .addEventListener('click', collapseCordian)
      return () => {
        const previous = document.querySelector('.-previous')
        if (previous) {
          previous.removeEventListener('click', collapseCordian)
        }
        const next = document.querySelector('.-next')
        if (next) {
          next.removeEventListener('click', collapseCordian)
        }
      }
    }
  }, [showUnmarked])

   const paginationCall = async (data) => {
     const payload = {
       ...data,
       startDate: startDate,
       endDate: endDate,
       type: 'inventory',
       state: [`unmarked`],
       bank_id: selectedBank && selectedBank.id,
       account_id: selectedAccount && selectedAccount.id,
     }
     if (filterBy === 'show_all') {
      payload.state = ['open', 'close','unmarked']
    } else if (filterBy) {
      payload.state = [filterBy]
    }
    let response;
     if(filterBy === 'open') {
       response = await plaidServices.getAbnormalTransactionsRefund({
        ...payload,
      })
     }
    //  if(filterBy === 'show_all') {
    //   response = await plaidServices.allAbnormalTransactions({
    //    ...payload,
    //  })
    // }
     else{
      response = await plaidServices.getAbnormalTransactionsOpen({
        ...payload,
      })

     }


     return response
   }

  useEffect(() => {
    pagination.current.dataCall(1)
  }, [filterBy , selectedBank])

  // const paginationCallOpen = async (data) => {
  //   const payload = {
  //     ...data,
  //     startDate: startDateTwo,
  //     endDate: endDateTwo,
  //     type: '',
  //     state: ['open'],
  //     bank_id: selectedBankTwo && selectedBankTwo.id,
  //     account_id: selectedAccountTwo && selectedAccountTwo.id,
  //   }
  //   if (filterBy === 'show_all') {
  //     payload.state = ['open', 'close']
  //   } else if (filterBy) {
  //     payload.state = [filterBy]
  //   }

  //   const response = await plaidServices.getAbnormalTransactionsRefund({
  //     ...payload,
  //   })
  //   setTransactionDetailOpen([...response.data.pagination.data])
  //   return response
  // }

  function getTransactionTypes(data) {
    return (dispatch) => {
      return transactionServices.getTransactionTypes(data).then(
        (response) => {
          return setOptions(response.data)
        },
        (error) => {
          return Promise.reject(error)
        }
      )
    }
  }

  const onChangeSingle = async (e, id) => {
    let data = {
      transaction_type_id: Number(e.target.value),
      ids: [id],
    }
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message))
      pagination.current.dataCall()
      // paginationOpen.current.dataCall()
    })
  }

  const onChangeMultipleUnmark = async (e) => {
    let data = {
      transaction_type_id: Number(e),
      ids: pagination.current.state.checkedIds,
    }
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message))
      pagination.current.dataCall()
      paginationOpen.current.dataCall()
    })
  }

  const exportToCSV = async (check) => {
    try {
      let ids = []
      if (check === 'unmarked') ids = pagination.current.state.checkedIds
      else if (check === 'open') ids = paginationOpen.current.state.checkedIds
      let res = await transactionServices.exportCSV({ transactionIds: ids })
      if (check === 'unmarked') {
        res.data.forEach((t) => {
          t.vendor.email = ''
        })
      }
      let result = exportData(res.data)
      let ws = XLSX.utils.aoa_to_sheet(result)
      ws['!merges'] = [XLSX.utils.decode_range('A1:G1')]
      ws['!rows'] = [{ hpt: 20 }]
      ws['A1'].s = {
        font: {
          sz: 16,
          color: {
            rgb: 'FF0000',
          },
        },
        fill: {
          fgColor: { rgb: 'FFFF00' },
        },
        alignment: {
          horizontal: 'center',
          vertical: 'center',
        },
      }
      const get_header_row = (sheet) => {
        let range = XLSX.utils.decode_range(sheet['!ref'])
        let C,
          R = 1
        let headers = []
        for (C = range.s.c; C <= range.e.c; ++C) {
          let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })]
          var cell_address = { c: C, r: R }
          var cell_ref = XLSX.utils.encode_cell(cell_address)
          if (cell && cell.t) headers.push(cell_ref)
        }
        return headers
      }
      const h = get_header_row(ws)
      h.map(
        (a) =>
          (ws[a].s = {
            font: {
              bold: true,
            },
            alignment: {
              horizontal: 'center',
              vertical: 'center',
            },
          })
      )

      ws['!cols'] = fitToColumn(result)
      function fitToColumn(result) {
        let a = result[1].map((a, i) => ({
          wch: Math.max(
            ...result.map((a2) => (a2[i] ? a2[i].toString().length + 3 : 0))
          ),
        }))
        return a
      }
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Transaction Detail')
      XLSX.writeFile(wb, 'transaction_detail.xlsx')
    } catch (error) {
      console.log(error)
    }
  }

  const handleFile = async (file) => {
    const reader = new FileReader()
    const rABS = !!reader.readAsBinaryString
    reader.onload = async (e) => {
      const bstr = e.target.result
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' })
      const wsname = wb.SheetNames[0]
      const ws = wb.Sheets[wsname]
      const data1 = XLSX.utils.sheet_to_json(ws, { header: 1, defval: '' })
      const formattedData = formatData(data1)
      checkData(formattedData)
    }
    if (rABS) reader.readAsBinaryString(file)
    else reader.readAsArrayBuffer(file)
  }

  const checkData = async (data, i) => {
    let name = []
    let errors = [name]
    data &&
      data.forEach((d, index) => {
        let amount
        let cal = 0
        amount = d && Number(d.amount)
        d &&
          d.items &&
          d.items.forEach((i) => {
            cal += i.qty * i.price + i.shipping + i.taxes
          })
        if (cal > amount) {
          let err
          err = `In row ${index + 3} items must be equal or less than amount`
          name.push(err)
        }
        if (d.items.length > 0) {
          const duplicates = d.items
            .map((el, i) => {
              return d.items.find((element, index) => {
                if (i !== index && element.name === el.name) {
                  return el
                }
                return undefined
              })
            })
            .filter((x) => x)
          if (duplicates.length > 0) {
            let err = `In row ${index + 3} items name are repeated.`
            name.push(err)
          }
        }
      })
    if (errors[0].length > 0) {
      dispatch(alertActions.error(errors))
      errors = []
    } else {
      setData(data)
      setDataModal(true)
    }
  }

  const importDataSubmit = async (data) => {
    await transactionServices.importFromExcel(data)
    setDataModal(false)
    window.location.reload()
  }

  const onChangeMultipleOpenAndClose = async (e) => {
    let data = {
      transaction_type_id: Number(e.target.value),
      ids: paginationOpen.current.state.checkedIds,
    }
    await transactionServices.changeTransactionType(data).then((response) => {
      dispatch(alertActions.success(response.message))
      pagination.current.dataCall()
      paginationOpen.current.dataCall()
    })
  }
const openViewer = () => {
    setShowImageViewer(!showImageViewer)
  }

// for transaction detail modal
  const handleDetailTransaction = (data) => {
    setShowDetailModal(true)
    if(data.length !== 0) {
      setTransactionData(data)

    }
  }

  const columns = [
    {
      Header: 'Transaction Status Listing',
      headerClassName: 'headerPagination',
      // className: "test123",
      columns: [
        {
          Header: 'Transaction Date',
          accessor: 'date',
          className: 'action-center',
          Cell: ({ row, value }) => {
            return <div>{moment(value).format('LL')} </div>
          },
          maxWidth: 150,
          sortable: false
        },
        {
          Header: 'Order Number',
          accessor: 'date',
          className: 'action-center',
          Cell: ({ row, value }) => {
            return <div>
              {row._original.order && row._original.order.order_number ? row._original.order.order_number : '----'}
            </div>
          },
          maxWidth: 150,
          sortable: false
        },
        {
          // Header: "Name",
          // className: "action-center",
          // maxWidth: 400,
          // minWidth: 400,
          // width: 400,
          Header: () => (
            <div
              style={{
                textAlign: 'center'
              }}
            >
              Vendor
            </div>
          ),
          accessor: 'name',
          sortable: false,
          Cell: ({ row, value }) => (
            <div style={{ textAlign: 'center', width: '100%' }}>{value}</div>
          )
        },
        {
          Header: () => (
            <div
              style={{
                textAlign: 'center'
                // alignItems: "right",
                // alignContent: "right",
              }}
            >
              Amount
            </div>
          ),
          accessor: 'amount',
          sortable: false,
          // className: "action-center",
          Cell: ({ row, value }) => (
            <div
              style={{
                textAlign: 'center',
                width: '100%',
                color: row._original.status === 'Posted' ? 'black' : 'blue'
              }}
            >
              $ {value}
            </div>
          ),
          maxWidth: 120
        },
        {
          Header: 'Card',
          accessor: 'account',
          alignItems: 'left',
          className: 'action-center',
          maxWidth: 500,
          sortable: false,
          Cell: ({ row, value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>
                {value.official_name} {value.mask}{' '}
              </div>
            )
          },
          sortable: false
        },
        {
          Header: 'Transaction Account',
          accessor: 'transaction_type.name',
          className: 'action-center',
          sortable: false,
          Cell: ({ row, value }) => {
            return (
              <>
                <select
                  className=' form-control'
                  onChange={e => onChangeSingle(e, row._original.id)}
                  // defaultValue={row._original.transaction_type.id}
                >
                      {options &&
                    options.filter(opt => opt.label !== 'All').map((option, i) => (
                      <option
                        key={i}
                        value={option.id}
                        selected={
                          row._original.transaction_type && row._original.transaction_type.id === option.id
                        }
                      >
                        {option.label}
                      </option>
                    ))}
                </select>
              </>
            )
          },
          maxWidth: 200
        },

        {
          Header: 'Order Status',
          accessor: 'status',
          maxWidth: 150,
          sortable: false,
          Cell: ({ row, value }) => {
            return (
              <div style={{ textAlign: 'center', width: '100%' }}>
                {row._original.order && row._original.order.status}
              </div>
            )
          }
        },

        {
          Header: 'Transaction State',
          accessor: 'transaction_state.name',
          Cell: ({ row, value }) => {
            return <div>{value}</div>
          },
          maxWidth: 150,
          sortable: false
        },

        // {
        //   Header: 'Transaction Details',
        //   accessor: 'transaction_details',
        //   Cell: ({ row, value }) => {
        //     return  (
        //       <button
        //         className='btn btn-sm'
        //         style={{ borderColor: '#1d4b54', color: '#1d4b54' }}
        //         onClick={() => handleDetailTransaction([row._original])}
        //       >
        //         Details
        //       </button>
        //     )
        //   }
        // }
      ]
    }
  ]

  const findInventory = (id) => {
    return inventories.find((e) => e.id === id).name
  }

  const changeFormInv = (e, index) => {
    e.preventDefault()
    const { name, value, type } = e.target
    const transDetail = [...transactionDetail]
    transDetail[index][name] = Number(value) > 0 ? Number(value) : null
    setTransactionDetail(transDetail)
  }

  const submitInventory = async (event, id,tid) => {
    event.preventDefault();
	event.stopPropagation();

    let should_submit = 0

    if (!isNewShipment && order.sub_status === 'need attention') {
      dispatch(
        alertActions.error([
          [`There is no new shipment created or order substatus is still unchanged`],
        ])
      )
      return false
    }

		// Prevent form submission if notes character lenght is more then 500 characters
		if (order && order.notes && order.notes.length > 500) {
      dispatch(
        alertActions.error([
          [`Notes must be less then 500 characters`],
        ])
      )
      return false
    }

		// check for order number
    if( order && order.order_number.trim() === '') {
      dispatch(
        alertActions.error([
          [`Order number is required to save this transaction details`],
        ])
      );
      return false;
    }

    const result = transactionDetail.map((transaction) => {
      const newTransaction = {
        ...transaction,
        qty: transaction.qty || 0,
        price: transaction.price || 0,
        taxes: transaction.taxes || 0,
        shipping: transaction.shipping || 0,
      }
      return { ...newTransaction, order, vendor }
    })

    let ONRequire = 0
    result.forEach((item) => {
      if (item.new && item.new === true) {
        if (item.order.order_number === '') {
          ONRequire++
          return false
        }
      }
    })

    if (ONRequire > 0) {
      dispatch(
        alertActions.error([
          [`Order number is required to save this transaction details`],
        ])
      )
      return false
    }

    const oldTransactions = result.filter((item) => {
      return 'id' in item
    })

    let inventory_require = false

    if (oldTransactions.length > 0) {
      result.forEach((item) => {
        if (item.inventory_id === null) {
          inventory_require = true
          return false
        }
      })
    }
    if (inventory_require) {
      dispatch(
        alertActions.error([
          [`Select inventory to save this transaction details`],
        ])
      )
      return false
    }

    let cal = 0
    result.forEach((i) => {
      cal = i.qty * i.price + i.shipping + i.taxes + cal
    })

    if (cal > currentTran.amount) {
      dispatch(
        alertActions.error([
          ['Sum of inventory must be equal or less than transaction amount'],
        ])
      )
      return false
    }


    //this is function to restrict user storing quantity expected greater than quantity order

    // if (shipment.length > 0) {
    //   let count = 0
    //   transactionDetail.map((trans, ti) => {
    //     let sum = 0
    //     shipment.map((ship, si) => {
    //       const item = ship.items.filter((item) => {
    //         return item.inventory_id === trans.inventory_id
    //       })
    //       if (item.length > 0) {
    //         sum = sum + item[0].qty_expected
    //       }
    //     })
    //     if (sum > trans.qty_ordered) {
    //       count++
    //     }
    //     if (count > 0) {
    //       dispatch(
    //         alertActions.error([
    //           ['Quantity expected is greater quantity order'],
    //         ])
    //       )
    //       should_submit++
    //       return false
    //     }
    //   })
    // }


    // this is to restrict the user without enter the tracking number and courier in shipment
    if (shipment.length > 0){
      let count=0
      shipment.map((ship)=>{
        if(ship.courier==='' || ship.tracking_number===''){
          count++
        }
      })
      if(count>0){
        if (count > 0) {
          dispatch(
            alertActions.error([
              ['Please select the courier and enter the tracking number in shipment'],
            ])
          )
          should_submit++
          return false
        }
      }
    }

    if (should_submit > 0) {
      return false
    }

	// Make submit button state disable true
	await setSubmitted(true);

    const data = {
      items: result,
      transaction_id: id,
      order: order,
      vendor: vendor,
      shipments: shipment,
      email_id: emailId
    }

    transactionServices.bulkUpdateItems({
      oldTransactions,
      order,
      shipments: shipment,
    })

    transactionServices.storeAndImportItemsBadge({ data, type: 'store' }).then(
      (response) => {
        transactionServices.getTrackingDetails(tid) //api to store the data of shipments tracking number for ups
		// Make submit button state false again
		setSubmitted(false);
        dispatch(alertActions.success(response.message))
        pagination.current && pagination.current.dataCall()
        // paginationOpen.current && paginationOpen.current.dataCall()
        setExpended({ 0: false })
        setExpended1({ 0: false })
        getInventoriesAsyncFunction()
      },
      (error) => {
        dispatch(alertActions.error(error))
      }
    )
  }

  const addRow = () => {
    let temp = JSON.parse(JSON.stringify(OC.TRANSACTIONDETAIL))
    setTransactionDetail((transactionDetail) => [...transactionDetail, temp])
  }

  const handleRemoveItem = (key) => {
    const newArr = [...transactionDetail]
    newArr.splice(
      newArr.findIndex((item, i) => i === key),
      1
    )
    setTransactionDetail(newArr)
  }

  const updateShipment = (e, ins) => {
    const { name, value, type } = e.target
    const ship = [...shipment]
    ship[ins][name] = value
    setShipment(ship)
  }

  const setOrderValues = (e) => {
    setOrder({ ...order, [e.target.name]: e.target.value })
    setCheckOrderStatus(true)
  }

  // const deleteItem = (item_id) => {
  //   const tranDtls = transactionDetail.filter(({ id }) => id !== item_id)

  //   transactionServices.deleteItem(item_id).then((response) => {
  //     dispatch(alertActions.success(response.message))
  //     setTransactionDetail(tranDtls)
  //     pagination.current.dataCall()
  //     paginationOpen.current.dataCall()
  //     setExpended({ 0: false })
  //     window.location.reload()
  //   })
  // }

  const appendShipment = () => {
    setOldShipment(JSON.parse(JSON.stringify(shipment)))
    let newDataShipment = JSON.parse(JSON.stringify(shipmentObject))

    if(shipment.length>0){
      // shipment.map((shipment,sid)=>{
      transactionDetail.map((item,iid)=>{
        if (
          item.qty_ordered !== item.qty &&
          ((item.qty_damaged !== null && item.qty_damaged !== 0) ||
            (item.qty_missing !== null && item.qty_missing !== 0))
        ) {
          let newDataItem = JSON.parse(JSON.stringify(shipmentObjectItems))
          newDataItem.qty_expected = JSON.parse(
            JSON.stringify(item.qty_ordered - item.qty)
          )
          newDataItem.inventory_id = JSON.parse(
            JSON.stringify(item.inventory_id)
          )
          item.qty_damaged = 0
          item.qty_missing = 0
          newDataShipment.items.push(newDataItem)
        }
      })
    // })

      if(newDataShipment.items.length>0)
      {
        setIsNewShipment(true)
        setShipment((shipment) => [...shipment, newDataShipment])
      }
      else{
         dispatch(
            alertActions.error([
              [
                'No more missing or damage quantity remains',
              ],
            ])
          )
          return false
      }
    }
  }

  const removeShipment = (id,sid) => {
    const newShipments = shipment.filter((s, i) => {
      return id !== i
    })
    setIsNewShipment(false)
    setShipment(oldShipment)
    setOldShipment([])
  }

  const showItems = (row, check) => {
    const returedItemsData =  row.original.returnshipments
    const filterReturnItem = returedItemsData? returedItemsData.map(i => i.returnshipmentitem) : []

    let defaultAddress =  row.original.order && addresses.findIndex(el => el.id === row.original.order.address_id)
    let defaultEmail = row.original.email_id && emails.findIndex(el => el.id === row.original.email_id)

    let savedItems = 0
    if (row.original)
      transactionDetail.forEach((t) => {
        if (t['new'] === undefined) {
          savedItems += 1
        }
      })
    return (
      <form
        onSubmit={(event) => {
          submitInventory(event, row.original.transaction_id,row.original.id)
        }}
        style={{ overflowX: 'scroll' }}
      >
        <div style={{ width: '95%', margin: 'auto' }}>
          <div className='row'>
            <div className='col-sm-3'>
              <FieldGroup
                id='order_number'
                type='text'
                label='ORDER NUMBER'
                style={{minHeight:"45px"}}
                name='order_number'
                value={order ? order.order_number : ''}
                onChange={(e) => {
                  setOrderValues(e)
                }}
              />
            </div>
            <div className='col-sm-3'>
              <div className="form-group">
                <label style={{ fontSize: "12px" }}>EMAIL</label>
                <Select
                  getOptionLabel={option =>
                    `${option.id ? option.email : ''}`
                  }
                  getOptionValue={option => option.id}
                  defaultValue={defaultEmail != -1 ? emails[defaultEmail] : ""}
                  className='new-font-size select-dropdown'
                  style={{ borderRadius: '5px'}}
                  options={emails}

                  onChange={handleChangeDropdown}
                  placeholder='Select Email'
                  name='selectedEmail'
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form-group'>
                <label style={{ fontSize: '12px' }}>ORDER STATUS</label>
                <select
                   style={{minHeight:"45px"}}
                  className='form-control'
                  name='status'
                  onChange={setOrderValues}
                >
                  <option value=''>-----</option>
                  {ORDER_STATUSES.map(({ value, label }) => {
                    return (
                      <option
                        key={value}
                        value={value}
                        selected={order && order.status === value}
                      >
                        {label}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-sm-3'>
              <div className='form-group'>
                <label style={{ fontSize: '12px' }}>ORDER SUB STATUS</label>
                <select
                 style={{minHeight:"45px"}}
                  className='form-control'
                  name='sub_status'
                  onChange={setOrderValues}
                >
                  <option value=''>-----</option>
                  {ORDER_SUB_STATUSES.map(({ value, label }) => {
                    return (
                      <option
                        key={value}
                        value={value}
                        selected={order && order.sub_status === value}
                      >
                        {label}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-sm-8'>
              <label style={{ fontSize: "12px" }}>WAREHOUSE ADDRESS</label>
              <Select
                getOptionLabel={option =>
                  `${option.id ? option.address : ''}`
                }
                getOptionValue={option => option.id}
                className='new-font-size'
                style={{ borderRadius: '5px' }}
                options={addresses}
                defaultValue={defaultAddress != -1 ? addresses[defaultAddress] : ''}
                onChange={handleChangeDropdown}
                // onChange={(e) => {
                //   setOrder({ ...order, address_id: e.id });
                // }}
                placeholder='Select Address'
                name='address_id'
                isClearable={true}
                isSearchable={true}
              />
              {/* <FieldGroup
                id='shipping_address'
                type='text'
                label='SHIPPING ADDRESS'
                name='shipping_address'
                value={order ? order.shipping_address : ''}
                onChange={setOrderValues}
              /> */}
            </div>
            {/* <div className="col-sm-2">
              <FieldGroup
                id="Taxes"
                type="number"
                label="Taxes"
                name="taxes"
                min='0'
                value={order ? order.taxes : 0}
                onChange={(e) => {
                  setOrder({ ...order, taxes: e.target.value })
                }}
              />
            </div>
            <div className="col-sm-2">
              <FieldGroup
                id="shipping"
                type="number"
                 min="0"
                label="Shipping"
                name="shippingNo"
                value={order ? order.shippingNo : 0}
                onChange={(e) =>{
                  setOrder({ ...order, shippingNo: e.target.value })
                }}
              />
            </div> */}
            {/* <div className='col-sm-2'>
              <FieldGroup
                id='zipcode'
                type='text'
                label='ZIP CODE'
                name='zipcode'
                value={order ? order.zipcode : ''}
                onChange={setOrderValues}
              />
            </div>
            <div className='col-sm-2'>
              <FieldGroup
                id='state'
                type='text'
                label='STATE'
                name='state'
                value={order ? order.state : ''}
                onChange={setOrderValues}
              />
            </div>
            <div className='col-sm-2'>
              <FieldGroup
                id='city'
                type='text'
                label='CITY'
                name='city'
                value={order ? order.city : ''}
                onChange={setOrderValues}
              />
            </div> */}
          </div>
					<div className='row' style={{ marginTop: "12px" }}>
						<label
							style={{
								fontSize: '12px',
								color: '#5d5d5d',
								marginLeft: "15px"
							}}
						>
							NOTE
						</label>
						<br></br>
						<div className='col-sm-8'>
							<textarea
								aria-multiline
								type='textarea'
								style={{ width: "100%", height: "120px", marginLeft: "12px", borderColor: "#cccccc", borderRadius: "5px" }}
								name='notes'
								value={ order ? order.notes : ""}
								placeholder="Enter note"
								onChange={(e) => {
									setOrder({ ...order, notes: e.target.value })
								}}
							>
							</textarea>
						</div>
					</div>
        </div>

        {/* dividing transaction details */}


        <table className='table' style={{marginTop: 20}}>
          <thead>

            <tr style={{borderBottomStyle: 'hidden'}} > <span style={{ marginLeft: 20 , fontSize : 16 }}> Items Details</span>
            <th></th>
            </tr>
            <tr style={{borderBottomStyle: 'hidden'}}>
              {/* <th></th> */}
              <th style={{paddingLeft: 25}}>Item</th>
              <th>Qunatity Ordered</th>
              <th>Cost</th>
              <th>{''}</th>
              {/* <th>Taxes</th>
              <th>Shipping</th> */}
            </tr>
          </thead>
          <tbody>
            {transactionDetail &&
              transactionDetail.map(function(item, ind) {
                return (
                  <>
                    <tr key={ind} style={{borderBottomStyle: 'hidden'}}>
                      {/* <td width='1px'>
                      </td> */}
                      <td style={{ width: "50%" , paddingLeft: 25}}>
                        <AsyncCreatableSelect
                          isMulti={false}
                          defaultOptions={inventories}
                          options={inventories}
                          maxMenuHeight={140}
                          defaultValue={
                            item.inventory_id
                              ? {
                                  id: item.inventory_id,
                                  name: returnColumnValueWithColumnMatch(
                                    inventories,
                                    'name',
                                    [item.inventory_id],
                                    'id'
                                  ),
                                }
                              : null
                          }
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          // defaultInputValue
                          loadOptions={loadOptions}
                          onChange={(selectedOption, name) =>
                            handleChangeDestination(selectedOption, name, ind)
                          }
                          className='selectUser select-dropdown'
                          placeholder='Select Inventory'
                          getNewOptionData={(inputValue, optionLabel) => ({
                            id: inputValue,
                            name: optionLabel,
                          })}
                        />
                      </td>
                      <td
                        className='bigFieldSec'
                        style={{ position: 'relative', width: '13%' }}
                      >
                        <input
                         style={{minHeight:"45px"}}
                          value={item.qty_ordered}
                          name='qty_ordered'
                          onChange={(e) => {
                            const { value } = e.target
                            const transDetail = [...transactionDetail]
                            if (Number(value) > 0) {
                              transDetail[ind].qty_ordered = Number(value)
                            } else {
                              transDetail[ind].qty_ordered = null
                            }
                            setTransactionDetail(transDetail)
                          }}
                          className='form-control leftPadding'
                          type='number'
                          min='0'
                        />
                      </td>
                      <td
                        className='bigFieldSec'
                        style={{ position: 'relative' , width: "13%"}}
                      >
                        <span
                          className='dollarSign'
                          //  style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input

                          value={item.price}
                          name='price'
                          onChange={(e) => changeFormInv(e, ind)}
                          className='form-control leftPadding'
                          type='number'
                          min='0'
                          step='any'
                          style={{ paddingLeft: '30px',minHeight:"45px" }}
                        />{' '}
                      </td>
                      {/* <td
                        className='bigFieldSec'
                        style={{ position: 'relative' }}
                      >
                        <span
                          className='dollarSign'
                          // style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input
                          value={item.taxes}
                          name='taxes'
                          onChange={(e) => changeFormInv(e, ind)}
                          className='form-control leftPadding'
                          type='number'
                          min='0'
                          step='any'
                          style={{ paddingLeft: '30px' }}
                        />{' '}
                      </td>
                      <td
                        className='bigFieldSec'
                        style={{ position: 'relative' }}
                      >
                        <span
                          className='dollarSign'
                          // style={{ marginTop: '6px' }}
                        >
                          $
                        </span>
                        <input
                          value={item.shipping}
                          name='shipping'
                          onChange={(e) => changeFormInv(e, ind)}
                          className='form-control leftPadding'
                          type='number'
                          min='0'
                          step='any'
                          style={{ paddingLeft: '30px' }}
                        />{' '}
                      </td> */}
                    </tr>

                  </>
                )
              })}
              {/* dividing items details */}
              {/* <br></br> */}
            {shipment &&
              shipment.map(function(ship, ins) {
                return (
                  <tr style={{borderBottomStyle: 'hidden'}}>
                    <td colSpan='6'>
                     <span style={{fontSize: 16}} >  Shipment Details </span>
                      <table className='table'>
                        <thead>
                          <tr style={{ borderBottomStyle: 'hidden'}}>
                            {/* <th>SHIPMENT</th> */}
                            <th>Item</th>
                            <th> Quantity Expected </th>
                            <th>Quantity Received</th>
                            <th>Missing</th>
                            <th>Damaged</th>
                            {/* <th>
                              <button
                                type='button'
                                value='remove'
                                className='btn btn-sm btn-danger'
                                style={{ display: 'flex', marginLeft: 'auto' }}
                                onClick={() => {
                                  removeShipment(ins,ship.id)
                                }}
                                disabled={ship.status!==null}
                              >
                                <i className='glyphicon glyphicon-remove'></i>
                                &nbsp;
                              </button>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {ship.items &&
                            ship.items.map((item, idx) => {
                              return (
                                <tr style={{ borderBottomStyle: 'hidden'}}>

                                  {/* {idx === 0 ? (
                                    <td className='smallFieldSec'>
                                      <input
                                        value={ins + 1}
                                        name='id'
                                        //className='form-control leftPadding'
                                        disabled={idx > 0}
                                        //type="number"
                                        min='1'
                                        //step="any"
                                        style={{
                                          paddingLeft: '25px',
                                          width: '40px',
                                          border: '0px',
                                          fontWeight: 500,
                                        }}
                                        readOnly
                                      />{' '}
                                    </td>
                                  ) : (
                                    <td className='smallFieldSec'></td>
                                  )} */}
                                  <td className='bigFieldThird' style={{ width: '25%'}}>
                                    <input
                                      value={
                                        item.inventory_id
                                          ? findInventory(item.inventory_id)
                                          : null
                                      }
                                      name={'inventory_id'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '15px' }}
                                      readOnly
                                    />
                                  </td>
                                  <td className='bigFieldThird' style={{ width: "8%"}}>
                                    <input
                                      value={item.qty_expected}
                                      name='qty_expected'
                                      // readOnly
                                      onChange={
                                        (e) => {
                                          let details = [...shipment]
                                          if (Number(e.target.value) > 0) {
                                            details[ins].items[
                                              idx
                                            ].qty_expected = Number(
                                              e.target.value
                                            )
                                          } else {
                                            details[ins].items[
                                              idx
                                            ].qty_expected = null
                                          }
                                          setShipment(details)
                                        }

                                      }
                                      className='form-control leftPadding'
                                      type='number'
                                      // min='0'
                                      // step='any'
                                      style={{ paddingLeft: '10px' }}
                                    />{' '}
                                  </td>
                                  {/* quantity recived */}
                                  <td className='bigFieldThird' style={{ width: "8%"}}>
                                    <input
                                      value={item.qty_received ? item.qty_received : 0}
                                      name={'inventory_id'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '10px' }}
                                      readOnly
                                    />
                                  </td>
                                  {/* qty-missing */}
                                  <td className='bigFieldThird' style={{ width: "8%"}}>
                                    <input
                                      value={item.qty_missing ? item.qty_missing : 0}
                                      name={'inventory_id'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '10px'  }}
                                      readOnly
                                    />
                                  </td>

                                  {/* qty-damage */}
                                  <td className='bigFieldThird' style={{ width: "8%"}}>
                                    <input
                                      value={item.qty_damaged ? item.qty_damaged : 0}
                                      name={'inventory_id'}
                                      className='form-control leftPadding'
                                      style={{ paddingLeft: '10px' }}
                                      readOnly
                                    />
                                  </td>
                                  {/* <td></td> */}
                                </tr>
                              )
                            })}
                          <tr style={{borderBottomStyle: 'hidden'}}>
                            <td colSpan='14'>
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                }}
                              >
                                <div
                                  style={{
                                    flexDirection: 'column',
                                    marginLeft: '0px',
                                  }}
                                >
                                  <th
                                    style={{
                                      fontSize: '12px',
                                      color: '#9a9a9a',
                                      fontWeight: 500,
                                    }}
                                  >
                                    COURIER
                                  </th>
                                  <select
                                    className='form-control'
                                    name='courier'
                                    onChange={(e) => updateShipment(e, ins)}
                                    // disabled={true}
                                  >
                                    <option value=''>-----</option>
                                    {CARRIERS.map(({ value, label }) => {
                                      return (
                                        <option
                                          key={value}
                                          value={value}
                                          selected={ship.courier === value}
                                        >
                                          {label}
                                        </option>
                                      )
                                    })}
                                  </select>{' '}
                                </div>
                                <div
                                  style={{
                                    marginLeft: '20px',
                                    flexDirection: 'column',
                                  }}
                                >
                                  {' '}
                                  <th
                                    style={{
                                      fontSize: '12px',
                                      color: '#9a9a9a',
                                      fontWeight: 500,
                                    }}
                                  >
                                    TRACKING NUMBER
                                  </th>
                                  <input
                                    value={ship.tracking_number}
                                    name='tracking_number'
                                    type='text'
                                    // readOnly
                                    onChange={(e) => {
                                      updateShipment(e, ins)
                                    }}
                                    className='form-control leftPadding'
                                    style={{ width: '250px' }}
                                  />
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                )
              })}

            <tr style={{borderBottomStyle: 'hidden'}}>
              <td colSpan='14'>
                {shipment.length === 0 && (
                  <button
                    onClick={addRow}
                    type='button'
                    className='btn btn-sm btn-info'
                  >
                    <i className='glyphicon glyphicon-plus'></i>
                    &nbsp;
                  </button>
                )}
                {hasNew && shipment.length === 0 && (
                  <button
                    type='button'
                    value='remove'
                    className='ml-sm  btn btn-sm btn-danger'
                    onClick={handleRemoveItem}
                  >
                    <i className='glyphicon glyphicon-remove'></i>&nbsp;
                  </button>
                )}

                { filterBy === 'open' ? "" :
                <input
                  type='button'
                  value={'Add Shipment'}
                  onClick={() => {
                    appendShipment()
                  }}
                  className='btn btn-sm btn-info ml-sm'
                />
                }
              </td>
            </tr>

            {/* return items on abnormal page */}

            {filterBy === 'open' && row.original.returnshipments.length > 0 && filterReturnItem.length > 0 &&
              filterReturnItem.map((item, ind) => {
                return (
                  <>
                  <span style={{ marginLeft: 20}}> Returns Item Details</span>
                    {item.map((i, k) => {
                      return (
                        <tr style={{ borderStyle: 'hidden'}} >
                          <td colSpan="18">
                            <tr key={k} style={{ borderBottomStyle: 'hidden'}}>
                              <td style={{ width: '240px', padding: '6px' }}>
                                <label
                                  style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, padding: '2px 2px' }}>
                                  ITEM RETURN
                                </label>

                                <input
                                  value=    {
                                    i.inventory_id ? findInventory( Number( i.inventory_id))
                                    : null
                                   }
                                  name={"inventory_id"}
                                  className="form-control leftPadding"
                                  style={{ paddingLeft: "15px" }}
                                  disabled={true}
                                />

                              </td>
                              <td>
                                <label
                                  style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, padding: '2px 2px' }}>
                                  QUANTITY RETURN
                                </label>
                                <input
                                  value={i.return_item_qty}
                                  name="returnQty"
                                  style={{ width: '120px' }}
                                  disabled={true}
                                  className="form-control leftPadding"
                                  type="number"
                                  min="0"
                                />
                              </td>
                            </tr>
                          </td>
                        </tr>
                      )
                    })}
                  </>
                );
              })}

             {filterBy === 'open' && row.original.returnshipments.length > 0 && returedItemsData &&
              <tr style={{ borderBottomStyle: 'hidden'}}>
                <td colSpan="14">
                  <div
                    style={{ display: "flex", flexDirection: "row", marginLeft: '-65px' }}
                  >
                    <div
                      style={{ flexDirection: "column", marginLeft: "75px" }}
                    >
                      <th
                        style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, }}
                      >
                        COURIER RETURN
                      </th>
                      <input
                        value={returedItemsData[0].courier}
                        name="courier"
                        type="text"
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                        disabled={true}
                      />

                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column", }}
                    >
                      <th
                        style={{ fontSize: "12px", color: "#9a9a9a", fontWeight: 500, }}
                      >
                        RETURN TRACKING NUMBER
                      </th>
                      <input
                        value={returedItemsData[0].tracking_no}
                        name="trackingNumber"
                        type="text"
                        disabled={true}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>
                    <div
                      style={{ marginLeft: "20px", flexDirection: "column" }}
                    >
                      <th
                        style={{
                          fontSize: "12px", color: "#9a9a9a", fontWeight: 500,
                        }}
                      >
                        RETURN RMA NUMBER
                      </th>
                      <input
                        value={returedItemsData[0].rma_no}
                        name="rmaNumber"
                        type="number"
                        disabled={true}
                        className="form-control leftPadding"
                        style={{ width: "250px" }}
                      />
                    </div>

                  </div>
                </td>
              </tr>
            }
            {/* end returns items */}
            <tr style={{ borderBottomStyle: 'hidden'}}>
              <td colSpan={10}>
                <div style={{ display: "flex", flexDirection: "row", float: 'right' }} >
                  <div style={{ marginLeft: "20px", flexDirection: "column" }} >

                    <FieldGroup
                      id="Taxes"
                      type="number"
                      label="Taxes"
                      step="0.01"
                      name="taxes"
                      min='0'
                      value={order ? order.taxes : 0}
                      onChange={(e) => {
                        setOrder({ ...order, taxes: e.target.value })
                      }}
                    />
                  </div>

                  <div style={{ marginLeft: "20px", flexDirection: "column" }} >
                    <FieldGroup
                      id="shipping"
                      type="number"
                      min="0"
                      step="0.01"
                      label="Shipping"
                      name="shippingNo"
                      value={order ? order.shippingNo : 0}
                      onChange={(e) => {
                        setOrder({ ...order, shippingNo: e.target.value })
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>

          </tbody>

          <tfoot>
            <tr>
              <td colSpan='14'>
                <span
                  className='pull-right'
                  style={{ position: 'sticky', right: '5px' }}
                >
                  <input
                    type='submit'
                    value={'Save'}
					disabled = { submitted }
                    className='btn btn-md btn-info'
                  />
                  <input
                    type='button'
                    value={'Cancel'}
                    onClick={() => {
                      // toggleExpanded(row.index, check)
                      setExpended({ 0: false })
                      setExpended1({ 0: false })
                      setShipment([])
                    }}
                    className='btn btn-md btn-danger ml-sm'
                  />
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </form>
    )
  }

  const manipulateData = (row) => {
    return row.items.length > 0
      ? row.items.map((obj) => ({
          ...obj,
          order: { ...row.order },
          vendor: { ...row.vendor },
        }))
      : [OC.TRANSACTIONDETAIL]
  }

  async function expandChange(newExpanded, index, event, cellinfo, check) {
    // console.log('expandChnage function')
    const row = cellinfo.original
    const transDet = await manipulateData(row)
    //console.log('transDet',transDet)
    setCurrentTran(row)
    setTransactionDetail(transDet)
    toggleExpanded(cellinfo.index, check)
    const object = {
      order_number:
        row.order && row.order.order_number ? row.order.order_number : "",
      tracking_number:
        row.order && row.order.tracking_number ? row.order.tracking_number : "",
      shipping_address:
        row.order && row.order.shipping_address
          ? row.order.shipping_address
          : "",
      zipcode: row.order && row.order.zipcode ? row.order.zipcode : "",
      state: row.order && row.order.state ? row.order.state : "",
      city: row.order && row.order.city ? row.order.city : "",
      status: row.order && row.order.status ? row.order.status : "",
      address_id: row.order && row.order.address_id ? row.order.address_id : "",
      taxes: row.order && row.order.taxes ? row.order.taxes : 0,
      shippingNo: row.order && row.order.shippingNo ? row.order.shippingNo : 0,
      notes: row.order && row.order.notes ? row.order.notes : "",
    };
    setOrder(object);
    setShipment(cellinfo.original.shipments)
    setEmailId(row.email_id)
  }

  async function expandChangeOpen(newExpanded, index, event, cellinfo, check) {
    //console.log('expandChangeOpen function')
    const row = cellinfo.original
    //console.log('row data in function',row)
    const transDet = await manipulateData(row)
    //console.log('transDet',transDet)
    setTransactionDetail(transDet)

    setCurrentTran(row)

    const selectTran = transactionDetailOpen[index[0]]
    const empty_object = {
      order_number: selectTran.order_number,
      tracking_number: selectTran.tracking_number,
      shipping_address: selectTran.order && selectTran.order.shipping_address,
      zipcode: selectTran.order && selectTran.order.zipcode,
      state: selectTran.order && selectTran.order.state,
      city: selectTran.order && selectTran.order.city,
      status: selectTran.order && selectTran.order.status,
    }
    setOrder({ ...empty_object })
    //console.log('row.shipments', row.shipments)
    if (row.shipments !== undefined && row.shipments.length > 0) {
      //console.log('if')
      setShipment(row.shipments)
    } else {
      //console.log('else')
      setShipment([])
    }
    toggleExpanded(cellinfo.index, check)
  }

  const toggleExpanded = (index, check) => {
    // console.log('inside toogle expand')
    if (check === 'unmarked') {
      const existingExpandValue = Object.values(expanded).indexOf(true) >= 0
      setExpended({ [index]: !existingExpandValue })
    } else if (check === 'other') {
      const existingExpandValue = Object.values(expanded1).indexOf(true) >= 0
      setExpended1({ [index]: !existingExpandValue })
    }
  }

  useEffect(() => {
    if (transactionDetail) {
      //console.log('inside use effect if')

      var result = transactionDetail.some((e) => e.hasOwnProperty('new'))
      const transaction = transactionDetail[0]
      //console.log('transaction', transaction)
      if (!vendor) {
        setVendor(transaction.vendor)
      }

      if (!order) {
        setOrder(transaction.order)
      }

      setHasNew(result)
    }
  }, [transactionDetail])

  const getInventoriesAsyncFunction = (data = null) => {
    transactionServices.getInventoriesAsync(data).then((response) => {
      setInventories(response)
    })
  }

  const getAllAddressData = (data = null) => {
    addresServices.getAllAddress(data).then(response => {
      if (response.data) {
        setAddresses(response.data.pagination)
      }
    })
  }
  const getAllEmails = (data = null) => {
      emailServices.getAllEmails(data).then(response => {
        if (response.data) {
          setEmails(response.data.pagination)
        }
      })
  }

    const filterInventories = (inputValue) => {
    return inventories.filter((i) =>
      i.name.toLowerCase().includes(inputValue.toLowerCase())
    )
  }

  const loadOptions = (inputValue,callback) => {
    setTimeout(() => {
    callback(filterInventories(inputValue));
  }, 500);
  }

  const handleChangeDestination = (selectedOption, name, index) => {
    const selectedITem = transactionDetail[index]
    const item_name = selectedOption['name']
    if (name.action === 'select-option') {
      const temp = cloneDeep(transactionDetail)
      temp[index]['inventory_id'] = selectedOption.id
      setTransactionDetail(temp)
    }

    if (name.action === 'create-option') {
      transactionServices
        .storeInventory({ name: selectedOption.name })
        .then((response) => {
          dispatch(alertActions.success(response.message))
          const temp = cloneDeep(transactionDetail)
          temp[index]['inventory_id'] = response.inventory.id
          setTransactionDetail(temp)
        },
				(error) => {
					dispatch(alertActions.error(error));
				});
    }
  }

  const getRowProps = (rowInfo) => {
    return {
      className:
        rowInfo.transaction_type.name === 'inventory'
          ? 'showExpand'
          : 'showExpand', //'hideExpand',
    }
  }

  const detectChanges = (data, flag) => {
    if (flag === 'pagination') {
      setPaginationBulkAction(data.length > 0 ? true : false)
    } else if (flag === 'paginationOpen') {
      setPaginationOpenBulkAction(data.length > 0 ? true : false)
    }
  }

  useEffect(() => {
    plaidServices.getLinkPlaid('credit_card').then((response) => {
      getAccountsStore(response.link.link_token)
    })
  }, [])

  function getAccountsStore(cardLink, publicTokenObjectUpdated = false) {
    plaidServices.getBanks(data).then((response) => {
      setState({
        ...state,
        accountModal: {
          ...state.accountModal,
          show: false,
          bank_id: null,
        },
        banks: response.banks,
        cardLink: cardLink ? cardLink : state.cardLink,
        publicTokenObjectUpdated: publicTokenObjectUpdated,
      })
    })
  }

  useEffect(() => {
    if (state.publicTokenObjectUpdated) {
      setPublicTokenObject(null)
      plaidServices
        .exchangeToken({ publicTokenObject: state.publicTokenObject })
        .then((res) => {
          getAccountsStore(null, false)
        })
    }
  }, [state])

  // useEffect(() => {
  //   plaidServices.getBankAccounts()
  // }, [])

  const handleChangeDropdown = (selectedOptionType, info) => {
    if (info['action'] === 'select-option' && info['name'] === 'selectedBank') {
      setSelectedBank({ ...selectedOptionType })
      setBankAccounts([...selectedOptionType.accounts])
    }

    if (
      info['action'] === 'select-option' &&
      info['name'] === 'selectedAccount'
    ) {
      setSelectedAccount({ ...selectedOptionType })
    }

    if (info["action"] === "select-option" && info["name"] === "selectedEmail") {
      setEmailId(selectedOptionType.id)
  }

  if ( info["action"] === "select-option" && info["name"] === "address_id" ) {
    setOrder({ ...order, address_id: selectedOptionType.id });
  }
  if ( info["action"] === "clear" && info["name"] === "address_id" ) {
    setOrder({ ...order, address_id: '' });
  }

  if ( info["action"] === "clear" && info["name"] === "selectedEmail" ) {
    setEmailId('');
  }

    if (info['action'] === 'clear' && info['name'] === 'selectedBank') {
      setSelectedBank(null);
      setSelectedAccount(null)
    }

    if (info['action'] === 'clear' && info['name'] === 'selectedAccount') {
      setSelectedAccount(null)
    }
    // pagination.current.dataCall(1)
  }

  const handleChangeDropdownTwo = (selectedOptionType, info) => {
    if (
      info['action'] === 'select-option' &&
      info['name'] === 'selectedBankTwo'
    ) {
      setSelectedBankTwo({ ...selectedOptionType })
      setBankAccountsTwo([...selectedOptionType.accounts])
    }

    if (
      info['action'] === 'select-option' &&
      info['name'] === 'selectedAccountTwo'
    ) {
      setSelectedAccountTwo({ ...selectedOptionType })
    }

    if (info['action'] === 'clear' && info['name'] === 'selectedBankTwo') {
      setSelectedBankTwo({})
    }

    if (info['action'] === 'clear' && info['name'] === 'selectedAccountTwo') {
      setSelectedAccountTwo({})
    }
    // pagination.current.dataCall(1)
  }

  const customStyles = {
    option: (styles, state) => ({
      ...styles,
      cursor: 'pointer',
    }),
    control: (styles) => ({
      ...styles,
      cursor: 'pointer',
    }),
  }

  const { cardLink, banks, accountDateModal, bankCollapse } = state


  return (
    <div>
      <Grid fluid>
        <Row>
          <Card
            ctTableResponsive
            content={
              <div>
                <Grid fluid>
                  <Row>
                    <Col>
                      <div className='secHeading'>
                      <span className="fa fa-list" style={{ marginLeft: 10}}></span>
                        &nbsp;&nbsp; Abnormal Transactions</div>
                    </Col>
                    <Col></Col>

                    {/* {pagination.current &&
                      pagination.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div style={{ marginLeft: 30 }}>
                            <select
                              defaultValue={'DEFAULT'}
                              onChange={(e) => onChangeMultipleUnmark(e)}
                              className='form-control'
                            >
                              <option value='DEFAULT' disabled>
                                Select Type
                              </option>
                              {options &&
                                options.map((option, i) => (
                                  <option key={i} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                      )} */}
                    {/* {pagination.current &&
                      pagination.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div style={{ marginLeft: 30 }}>
                            <Button onClick={() => exportToCSV('unmarked')}>
                              Export
                            </Button>
                          </div>
                        </Col> */}
                      {/* )} */}
                    {/* <Col md={2}>
                      <DataInput handleFile={handleFile} />
                    </Col> */}



                    {/* trancation detil modal */}
                   {
                      showDetailModal && !!transactionData && (
                        <Modal
                          backdrop={'static'}
                          show={showDetailModal}
                          onHide={() => setShowDetailModal(false)}
                          aria-labelledby='ModalHeader'
                          dialogClassName='fullscreenmodal'

                        >
                          <Modal.Header closeButton>
                            <Modal.Title
                              id='ModalHeader'
                              className='headerTitle'
                              style={{ width: 800, marginLeft: '360px', fontSize: '30px' }}
                            >
                              Transaction Details
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            {transactionData.length > 0 &&
                              transactionData.map((data, idData) => {
                                return (
                                  <>
                                    <div className='row' key={idData}>
                                      <>
                                        <div style={styles.formContainer}>
                                          <div>
                                            <p style={{ ...styles.title, fontSize: '20px' }}>
                                              Order Details:
                                            </p>
                                          </div>
                                          <div style={styles.formInputsContainer}>
                                           <div style={styles.inputContainer}>
                                              <label>Order Number</label>

                                              <OverlayTrigger
                                                placement='bottom'
                                                trigger={['hover']}
                                                overlay={<Tooltip id='orderNo'>{data.order.order_number}</Tooltip>}
                                              >
                                                <input
                                                  type='text'
                                                  readOnly
                                                  style={{ ...styles.readonlyInput, textOverflow: 'ellipsis' }}
                                                  name='order_number'
                                                  value={data.order.order_number}
                                                />
                                              </OverlayTrigger>
                                            </div>

                                            <div style={styles.inputContainer}>
                                              <label>Merchant Name</label>

                                              <OverlayTrigger
                                                placement='bottom'
                                                trigger={['hover']}
                                                overlay={<Tooltip id='tooltip'>{data.merchant_name}</Tooltip>}
                                              >
                                                <input
                                                  style={{
                                                    ...styles.readonlyInput,
                                                    width: '140px',
                                                    textOverflow: 'ellipsis'
                                                  }}
                                                  readOnly
                                                  //disabled
                                                  name='merchant_name'
                                                  value={
                                                    data.merchant_name ? data.merchant_name : data.name ? data.name : null
                                                  }
                                                />
                                              </OverlayTrigger>

                                            </div>

                                            <div style={styles.inputContainer}>
                                              <label>Amount</label>
                                              <span
                                                style={{
                                                  ...styles.dollar, fontSize: 'large',fontWeight: '900', zIndex: 1
                                                }}
                                              >
                                                $ {data.amount ? data.amount : null}
                                              </span>

                                            </div>

                                            <div style={styles.inputContainer}>
                                              <label>Order Status </label>
                                              <span
                                               style={{
                                                fontWeight: 900, border: 'none',fontSize: 'large' , color: '#5858ed'
                                               }}
                                               >
                                                {data.order.status ? data.order.status : null}
                                              </span>
                                            </div>

                                            <div style={styles.inputContainer}>
                                              <label>Order Sub Status </label>
                                              <span
                                               style={{
                                                fontWeight: 900, border: 'none',fontSize: 'large' , color: '#dd8438'
                                               }}
                                               >
                                                { data.order.sub_status ? data.order.sub_status : null  }
                                              </span>

                                            </div>
                                          </div>

                                          <div style={{ marginTop: 20 }}>
                                            <p style={{ ...styles.title }}>Shipment Item Detail:</p>
                                          </div>
                                          {data.items.length > 0 &&
                                            data.items[0].inventory_id !== null &&
                                            data.shipments.length === 0 && (
                                              <>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '50px',
                                                    fontWeight: 900,
                                                    fontSize: '18px',
                                                    color: 'red'
                                                  }}
                                                >
                                                  There is no shipment created for this transaction
                                                </div>
                                              </>
                                            )}

                                          {data.items.length === 1 &&
                                            data.items[0].inventory_id === null && (
                                              <>
                                                <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    padding: '50px',
                                                    fontWeight: 900,
                                                    fontSize: '18px',
                                                    color: 'red'
                                                  }}
                                                >
                                                  There is no items in this order so you are not
                                                  able to see the items details.
                                                </div>
                                              </>
                                            )}

                                          {data.shipments.map((ship, sid) => {

                                            return (
                                              <>
                                                <div key={sid} style={{ marginLeft: '10px' }}>
                                                  {ship.items.map((item, idItems) => {

                                                    return (
                                                      <>
                                                        <div key={idItems}>
                                                          <div
                                                            style={{
                                                              ...styles.formInputsContainer,
                                                              justifyContent: 'left',
                                                              marginTop: idItems != 0 ? 20 : 0
                                                            }}
                                                          >
                                                            {/* {idItems === 0 && (
                                                              <div
                                                                style={{
                                                                  ...styles.inputContainer, marginLeft: 20
                                                                }}
                                                              >
                                                                <label>Shipment</label>
                                                                <input
                                                                  type='number'
                                                                  min='0'
                                                                  readOnly
                                                                  style={{
                                                                    ...styles.readonlyInput, width: '85px'
                                                                  }}
                                                                  name='item'
                                                                  value={sid + 1}
                                                                />
                                                              </div>
                                                            )} */}
                                                            {/* {idItems > 0 && (
                                                              <div
                                                                style={{
                                                                  ...styles.inputContainer,
                                                                  marginLeft: 20
                                                                }}
                                                              >
                                                                <label></label>
                                                                <input
                                                                  type='number'
                                                                  min='0'
                                                                  readOnly
                                                                  style={{
                                                                    ...styles.readonlyInput, width: '85px'
                                                                  }}
                                                                  name='item'
                                                                  value={''}
                                                                />
                                                              </div>
                                                            )} */}

                                                            {idItems === 0 && (
                                                              <div
                                                                style={{
                                                                  ...styles.inputContainer
                                                                }}
                                                              >
                                                                <label>Tracking No</label>
                                                                <OverlayTrigger
                                                                  placement='bottom'
                                                                  trigger={['hover']}
                                                                  overlay={<Tooltip id='TrackingNo'
                                                                  >{ship.tracking_number}</Tooltip>}
                                                                >
                                                                  <input
                                                                    type='text'
                                                                    min='0'
                                                                    readOnly
                                                                    style={{
                                                                      ...styles.readonlyInput, width: '105px',
                                                                      fontSize: 15, paddingTop: '3px', textOverflow: 'ellipsis'
                                                                    }}
                                                                    name='item'
                                                                    value={ship.tracking_number}
                                                                  />

                                                                </OverlayTrigger>
                                                              </div>
                                                            )}
                                                            {idItems > 0 && (
                                                              <div
                                                                style={{
                                                                  ...styles.inputContainer
                                                                }}
                                                              >
                                                                <label></label>
                                                                <input
                                                                  type='number'
                                                                  min='0'
                                                                  readOnly
                                                                  style={{
                                                                    ...styles.readonlyInput, width: '105px',
                                                                    fontSize: 15, paddingTop: '3px'
                                                                  }}
                                                                  name='item'
                                                                  value={null}
                                                                />
                                                              </div>
                                                            )}

                                                            <div
                                                              style={{
                                                                ...styles.inputContainer, width: 75, marginLeft: 10
                                                              }}
                                                            >
                                                              <label>Item</label>
                                                              <span
                                                                style={{
                                                                  ...styles.readonlyInput,
                                                                  width: '75px',
                                                                  fontSize: 15,
                                                                  paddingTop: '3px'
                                                                }}>
                                                               {
                                                                 item.inventory_id ? findInventory( item.inventory_id)
                                                                 : null
                                                                }
                                                              </span>
                                                            </div>

                                                            <div
                                                              style={{
                                                                ...styles.inputContainer,
                                                                marginLeft: 20
                                                              }}
                                                            >
                                                              <label>Qty Shipped</label>
                                                              <input
                                                                type='text'
                                                                readOnly
                                                                style={{ ...styles.readonlyInput,   width: '110px'  }}
                                                                name='qty_expected'
                                                                value={item.qty_expected + 'X'}
                                                              />
                                                            </div>

                                                            <div
                                                              style={{ ...styles.inputContainer }}
                                                            >
                                                              <label>Qty Received</label>
                                                              <span style={{ color: '#089508d9' }}>
                                                                {item.qty_received === null
                                                                  ? item.qty_expected: item.qty_received}
                                                              </span>
                                                            </div>

                                                            <div
                                                              style={{...styles.inputContainer, marginLeft: 50 }}
                                                            >
                                                              <label> Missing</label>
                                                              <span style={{color: '#0f0fe2'}}>
                                                                {item.qty_missing  ? item.qty_missing : 0}
                                                              </span>
                                                            </div>

                                                            <div
                                                              style={{
                                                                ...styles.inputContainer, marginLeft: 50
                                                              }}
                                                            >
                                                              <label>Damaged</label>
                                                              <span style={{color: 'red'}}>
                                                                {item.qty_damaged ? item.qty_damaged : 0}
                                                              </span>
                                                            </div>

                                                            {/* <div
                                                              style={{...styles.inputContainer, marginLeft: 50 }}
                                                            >

                                                              <label> Missing Price</label>
                                                              <span style={{ color: '#0f0fe2' }}>
                                                                {(data.items[idItems].inventory_id ===
                                                                  item.inventory_id )
                                                                  ? `$
                                                                  ${data.items[idItems].price * (item.qty_missing + item.qty_damaged) }`
                                                                  : 'N/A'}
                                                              </span>
                                                            </div> */}

                                                            {item.images.length !== 0 ? (
                                                              <div
                                                                style={{  marginLeft: '50px' }}
                                                              >
                                                                <button
                                                                  className='btn btn-sm'
                                                                  style={{borderColor: '#1d4b54', color: '#1d4b54'}}
                                                                  onClick={() => {
                                                                    openViewer()
                                                                    setImageUploaderData({
                                                                      DataId: idData,
                                                                      ShipmentId: sid,
                                                                      ItemId: idItems
                                                                    })
                                                                  }}
                                                                >
                                                                  View Image
                                                                </button>
                                                              </div>
                                                            ) : (
                                                              <div
                                                                style={{
                                                                  ...styles.inputContainer,marginLeft: 49
                                                                }}
                                                              ></div>
                                                            )}
                                                          </div>
                                                        </div>
                                                      </>
                                                    )
                                                  })}
                                                </div>
                                                {sid + 1 > 0 && sid + 1 < transactionData[idData].shipments.length &&
                                                 (  <hr /> )
                                                }
                                              </>
                                            )
                                          })}

                                          {/* details of return items */}
                                          { data.returnshipments && data.returnshipments.map((ship, sid) => {

                                            return (
                                              <>
                                                <div style={{ marginTop: 20 }}>
                                                 <p style={{ ...styles.title }}>Return Items Detail:</p>
                                               </div>
                                                <div key={sid} style={{ marginLeft: '10px' }}>

                                                  {ship.returnshipmentitem.map((item, idItems) => {

                                                    return (
                                                      <>
                                                        <div key={idItems}>
                                                          <div
                                                            style={{
                                                              ...styles.formInputsContainer,
                                                              justifyContent: 'left',
                                                              marginTop: idItems != 0 ? 20 : 0
                                                            }}
                                                          >

                                                            <div
                                                              style={{...styles.inputContainer, marginLeft: 5 }}
                                                            >
                                                              <label> Qty Return</label>
                                                              <span style={{color: '#0f0fe2'}}>
                                                                {item.return_item_qty  ? item.return_item_qty : 0}
                                                              </span>
                                                            </div>

                                                          </div>
                                                        </div>
                                                      </>
                                                    )
                                                  })}
                                                </div>

                                              </>
                                            )
                                          })}
                                        </div>
                                      </>
                                    </div>
                                  </>
                                )
                              })}
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              bsStyle='danger'
                              onClick={() => setShowDetailModal(false)}
                              style={{ border: 'white', color: 'white', backgroundColor: '#EE2D20' }}
                            >
                              Close
                            </Button>
                          </Modal.Footer>
                          {showImageViewer && (
                            <ImageViewerModal
                              open={showImageViewer}
                              setOpen={() => {  setShowImageViewer(false) }}
                              imageUploaderData={imageUploaderData}
                              setImageUploaderData={() => {
                                setImageUploaderData({ DataId: '', ItemId: '', ShipmentId: '' })
                              }}
                              stateData={transactionData}
                              setStateData={setTransactionData}
                            />
                          )}
                        </Modal>
                      )
                    }

                    {/* end transaction detail modal */}

                    {dataModal && (
                      <Modal
                        backdrop={'static'}
                        show={dataModal}
                        onHide={() => setDataModal(false)}
                        aria-labelledby='ModalHeader'
                        dialogClassName='fullscreenmodal'
                      >
                        <Modal.Header closeButton>
                          <Modal.Title
                            id='ModalHeader'
                            className='headerTitle'
                            style={{ width: 800 }}
                          >
                            Import Data Preview
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className='row'>
                            <div className='col-md-12'>
                              <Card
                                bsClass={['innerCard mb-none']}
                                content={<Accordion data={data} />}
                              />
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            bsStyle='danger'
                            onClick={() => setDataModal(false)}
                            style={{
                              border: 'white',
                              color: 'white',
                              backgroundColor: '#EE2D20',
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            bsStyle='primary'
                            style={{
                              border: 'white',
                              color: 'white',
                              backgroundColor: '#2F4576',
                            }}
                            onClick={() => importDataSubmit(data)}
                          >
                            Upload
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    )}
                    <Col md={12}>
                      <div className='panel panel-default'>

                      <div
                          className="row"
                          style={{ margin: "auto", background: "#F7F8FA" }}
                        >
                          <div className="col-md-8">
                            <ul

                             className="tabsLst"
                            >
                              <li>
                                <div
                                 style={{
                                  marginBottom: -1,
                                  backgroundColor: evertThingElse && "#006EEB",
                                  color: evertThingElse && "white",
                                }}
                                  className="btn btn-default btn-sm  tab_lbl"

                                  onClick={() => {
                                    setAll(false);
                                    setEvertThingElse(true)
                                    setRemaining(false)
                                    // setOpen(false);
                                    // setClose(false);
                                    // setUnmark(true);
                                    setFilterBy('unmarked');
                                    setStartDate(startDateDefault)
                                    setEndDate(today);
                                    setSelectedBank(null)
                                    setExpended({ 0: false })
                                    setExpended1({ 0: false })
                                    setSelectedAccount(null)
                                    // setSelectedIds([])
                                  }}
                                >
                                  Open Cases
                                </div>
                              </li>

                              <li>
                                <div
                                  className="btn btn-default btn-sm  tab_lbl"
                                  style={{
                                    marginBottom: -1,
                                    backgroundColor: remaining && "#006EEB",
                                    color: remaining && "white",
                                  }}
                                  onClick={() => {
                                    setAll(false);
                                    setEvertThingElse(false);
                                    setRemaining(true);
                                    // setUnmark(false);
                                     setFilterBy("open");
                                     setStartDate(startDateDefault)
                                    setEndDate(today);
                                    setSelectedBank(null)
                                    setSelectedAccount(null)
                                    setExpended({ 0: false })
                                    setExpended1({ 0: false })
                                    // setSelectedIds([])
                                  }}
                                >
                                  Pending Refunds,Returns
                                </div>
                              </li>


                            </ul>
                          </div>
                          <div
                            className="col-md-4"

                          >

                          <ul
                            style={{
                              justifyContent: "right",
                              listStyle: "none",
                              display: "flex",

                            }}
                            // className="tabsLst"
                          >
                            <li>
                              {/* <DropdownButton
                                bsStyle="default"
                                noCaret
                                onToggle={() => setOpenToogle(!openToogle)}
                                open={openToogle}
                                pullRight
                                role="menu"
                                className="btn headingBtn tab_btn"
                                id="dropdown-no-caret"
                                title={
                                  <span className="fa fa-ellipsis-v"></span>
                                }

                              >
                                <label htmlFor="file" className="btn menu" onClick={() => setOpenToogle(false)}>
                                <DataInput handleFile={handleFile} />
                                </label>


                              </DropdownButton> */}
                            </li>
                          </ul>



                          </div>
                        </div>

                        {showUnmarked && (

                       <div className="panel-body">
                         <Pagination
                           style={{
                             fontSize: "14px",
                             textAlign: "left",
                             alignItems: "left",
                             alignContent: "left",
                           }}
                           ref={pagination}
                           showPagination={true}
                           columns={columns}
                           pageSize={50}
                           noDataText="No Item found"
                           getDataCall={paginationCall}
                           filterPlaceHolder=""
                           ids=""
                           filterViewForFilter={true}
                           selectMulti={false}
                           multiSelector={"id"}
                           showTotal={false}
                           // SubComponent={(row) => {
                           //   return showItems(row, "other");
                           // }}
                           SubComponent={(row) => {
                             if (
                               row.original.transaction_type.name ===
                               'refund'
                             ) {
                               return (
                                 <ShowRefundItems
                                   row={row}
                                   check={'unmarked'}
                                   inventories={inventories}
                                   toggleExpanded={toggleExpanded}
                                   setExpended={setExpended}
                                   setExpended1={setExpended1}
                                   setRefreshEverythingElseData={
                                     setRefreshEverythingElseData
                                   }
                                 />
                               )
                             }
                             return showItems(row, 'unmark')
                           }}
                           onExpandedChange={(
                             newExpanded,
                             index,
                             event,
                             cellinfo
                           ) => {
                             // setOrder(null);
                             // setVendor(null);
                             // setShipment([]);
                             expandChange(
                               newExpanded,
                               index,
                               event,
                               cellinfo,
                               "unmarked"
                             );
                           }}
                           expanded={expanded}
                           detectChanges={detectChanges}
                           detectChangesAttr={"checkedIds"}
                           detectChangesFlag={"pagination"}
                          //  selectedIds={setSelectedIds}
                          //  ids={ids}
                           upperContent={
                             <React.Fragment>
                              <Col>
                               <Col xs={12} sm={12} md={2} lg={2}  style={{width: filterBy === 'show_all' ? `14%` : null}}>
                                 <FieldGroupTwo
                                   id="startDate"
                                   type="date"
                                   label="Start date"
                                   name="startDate"
                                   value={startDate}
                                   className= 'fieldGroup'
                                   // style={{width: filterBy === 'show_all' ? `50` : null}}
                                   onChange={(e) => {
                                     const d = e.target.value;
                                     if (!d.trim())
                                       setStartDate(startDateDefault);
                                     else setStartDate(d);
                                   }}
                                   max={endDate}
                                 />
                               </Col>

                               <Col xs={12} sm={12} md={2} lg={2} style={{width: filterBy === 'show_all' ? `14%` : null}}>
                                 <FieldGroupTwo
                                   id="endDate"
                                   type="date"
                                   label="End date"
                                   name="endDate"
                                   value={endDate}
                                   className="fieldGroup"
                                   onChange={(e) => {
                                     const d = e.target.value;
                                     if (!d.trim()) setEndDate(today);
                                     else setEndDate(d);
                                   }}
                                   max={today}
                                 />
                               </Col>

                               <Col xs={12} sm={12} md={2} lg={2} style={{width: filterBy === 'show_all' ? `14%` : null}}>
                                 <div className="form-group">
                                   <label htmlFor="email">
                                     Select Bank
                                   </label>
                                   <Select
                                     getOptionLabel={(option) =>
                                       option.bank_name
                                     }
                                     getOptionValue={(option) =>
                                       option.access_token
                                     }
                                     options={banks}
                                     value={selectedBank ? selectedBank : null}
                                     onChange={handleChangeDropdown}
                                     placeholder="Select Bank"
                                     className="new-font-size"
                                     name="selectedBank"
                                     isClearable={true}
                                   />
                                 </div>
                               </Col>

                               <Col xs={12} sm={12} md={2} lg={2}  style={{width: filterBy === 'show_all' ? `14%` : null}}>
                                 <div className="form-group">
                                   <label htmlFor="email">
                                     Select Account
                                   </label>
                                   <Select
                                     getOptionLabel={(option) =>
                                       `${
                                         option.label
                                           ? option.official_name
                                           : ""
                                       } ${option.mask}`
                                     }
                                     getOptionValue={(option) => option.id}
                                     className="new-font-size"
                                     style={{ borderRadius: "5px" }}
                                     options={bankAccounts}
                                     value={ selectedAccount? selectedAccount : null}
                                     onChange={handleChangeDropdown}
                                     placeholder="Select Bank Account"
                                     name="selectedAccount"
                                     isClearable={true}
                                   />
                                 </div>
                               </Col>
                            {filterBy === 'show_all' ? (
                            <Col xs={12} sm={12} md={2} lg={2} style={{width: filterBy === 'show_all' ? `14%` : null}}>
                                 <div className="form-group">
                                   <label htmlFor="email">
                                     Transaction 
                                   </label>
                                   <Select
                                     getOptionLabel={(option)=> option.label }
                                     getOptionValue={(option) =>  option.id}
                                     isOptionDisabled={(option)=> option.id === 7}
                                     className="new-font-size"
                                     style={{ borderRadius: "5px" }}
                                     options={options }
                                     onChange={handleChangeDropdown}
                                     placeholder="Select Account"
                                     name="transactionAccount"
                                     isClearable={true}
                                   />
                                 </div>
                               </Col>): null}



                               <Col md={1} lg={1} sm={12} xs={12}>
                                 <div style={{ marginTop: "21px" }}>
                                   <button
                                     type="submit"
                                     className="btn btn-lg srchBtn"
                                     onClick={(e) => {
                                       pagination.current.dataCall(1);
                                     }}
                                   >
                                     {/* <span className="fa fa-search"></span>{" "} */}
                                     Search
                                   </button>
                                 </div>
                               </Col>
                              </Col>

                              {/* {
                                  pagination.current &&
                                  pagination.current.state.checkedIds.length >
                                    0 ? (
                                     <Col md={12}>
                                     <ul style={{
                                       listStyle:"none",
                                       dispaly:"flex",
                                       padding:0,
                                       marginBottom:"1px"
                                     }}>
                                      <li style={{marginTop:"24px",marginBottom:"-17px"}}>
                                          <DropdownButton
                                        // bsStyle="selectType"
                                        title={title2}
                                        className="selectType"
                                        style={{fontWeight:'500',padding:"12px 21px"}}
                                        //  id="dropdown-basic-Default`"
                                        >
                                        <MenuItem eventKey="1" onClick={() => {
                                          // setTitle2("Export transaction ")
                                          exportToCSV("unmarked")}}>
                                            Export transaction
                                        </MenuItem>
                                        <MenuItem eventKey="2"  className="submenu" onClick={() => {
                                          //  setTitle2("Select Account ")
                                           }
                                        }

                                          >

                                            Select Account<span className="fa fa-caret-right"></span>
                                           <MenuItem>
                                            <ul role="menu" className="dropdown-menu subitems" >
                                             {options && options.map((option)=>(
                                               <li role="presentation" key={option.id} onClick={()=> onChangeMultipleUnmark(option.id)}>
                                               <a role="menuitem"> {option.name} </a>
                                                </li>
                                             ))}


                                            </ul>
                                            </MenuItem>
                                        </MenuItem>

                                        <MenuItem eventKey="4" disabled onClick={() =>
                                          {
                                          // setTitle2("Merge Transactions")

                                          }
                                         }>
                                           Merge Transactions
                                        </MenuItem>





                                      </DropdownButton>
                                          </li>
                                          </ul>
                                      </Col>
                                    ):null
                              } */}


                               {/* </Row> */}
                               {/* </Col> */}
                             </React.Fragment>
                           }
                         />
                       </div>
                     )}

                      </div>
                    </Col>
                    <Col md={12}></Col>
                    {/* {paginationOpen.current &&
                      paginationOpen.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div style={{ marginLeft: 30, marginTop: 20 }}>
                            <select
                              defaultValue={'DEFAULT'}
                              onChange={(e) => onChangeMultipleOpenAndClose(e)}
                              className='form-control'
                            >
                              <option value='DEFAULT' disabled>
                                Select Type
                              </option>
                              {options &&
                                options.map((option, i) => (
                                  <option key={i} value={option.id}>
                                    {option.label}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Col>
                      )} */}
                    {/* {paginationOpen.current &&
                      paginationOpen.current.state.checkedIds.length > 0 && (
                        <Col md={2}>
                          <div
                            style={{
                              marginLeft: 30,
                              marginTop: 20,
                              marginBottom: 30,
                            }}
                          >
                            <Button onClick={() => exportToCSV('open')}>
                              Export
                            </Button>
                          </div>
                        </Col>
                      )} */}
                    {/* <Col md={12}>
                      <div className='panel panel-default'> */}

                        {/* {(showEverythingElse || refreshEverythingElseData) && (
                          <div className='panel-body'>
                            <Pagination
                              style={{ fontSize: '14px', alignItems: 'left' }}
                              ref={paginationOpen}
                              showPagination={true}
                              columns={columns}
                              pageSize={50}
                              noDataText='No Item found'
                              getDataCall={paginationCallOpen}
                              filterPlaceHolder='by merchant, tracking &amp; order number'
                              filterViewForFilter={true}
                              selectMulti={true}
                              multiSelector={'id'}
                              SubComponent={(row) => {
                                if (
                                  row.original.transaction_type.name ===
                                  'refund'
                                ) {
                                  return (
                                    <ShowRefundItems
                                      row={row}
                                      check={'unmarked'}
                                      inventories={inventories}
                                      toggleExpanded={toggleExpanded}
                                      setExpended={setExpended}
                                      setExpended1={setExpended1}
                                      setRefreshEverythingElseData={
                                        setRefreshEverythingElseData
                                      }
                                    />
                                  )
                                }
                                return showItems(row, 'unmarked')
                              }}
                              onExpandedChange={(
                                newExpanded,
                                index,
                                event,
                                cellinfo
                              ) => {
                                expandChangeOpen(
                                  newExpanded,
                                  index,
                                  event,
                                  cellinfo,
                                  'other'
                                )
                              }}
                              expanded={expanded1}
                              expandableRowDisabled={(row) =>
                                row._original.transaction_type.name === 'refund'
                                  ? true
                                  : false
                              }
                              getRowProps={getRowProps}
                              //checkIfRefund={getCheckRefund}
                              detectChanges={detectChanges}
                              detectChangesAttr={'checkedIds'}
                              detectChangesFlag={'paginationOpen'}
                              filterContent={
                                <React.Fragment>
                                  <div className='form-group'>
                                    <label>Filter by Transaction State:</label>
                                    <select
                                      className='form-control'
                                      value={filterBy || 'open'}
                                      onChange={(e) => {
                                        setFilterBy(
                                          e.target.value
                                            ? e.target.value
                                            : 'null'
                                        )
                                        setExpended({ 0: false })
                                      }}
                                      style={{ cursor: 'pointer' }}
                                      // value={selectedFilterState}
                                    >
                                      <option value='show_all'>Show All</option>
                                      {tranStates.map(({ id, name, label }) => {
                                        return (
                                          <option key={id} value={name}>
                                            {label}
                                          </option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </React.Fragment>
                              }
                              upperContent={
                                <React.Fragment>
                                  <Col xs={12}>
                                    <Row>
                                      <Col xs={2}>
                                        <FieldGroupTwo
                                          id='startDateTwo'
                                          type='date'
                                          label='Start date'
                                          name='startDateTwo'
                                          value={startDateTwo}
                                          style={{ cursor: 'pointer' }}
                                          onChange={(e) => {
                                            const d = e.target.value
                                            if (!d.trim())
                                              setStartDateTwo(startDateDefault)
                                            else setStartDateTwo(d)
                                          }}
                                          max={endDate}
                                        />
                                      </Col>

                                      <Col xs={2}>
                                        <FieldGroupTwo
                                          id='endDateTwo'
                                          type='date'
                                          label='End date'
                                          name='endDateTwo'
                                          value={endDateTwo}
                                          style={{ cursor: 'pointer' }}
                                          onChange={(e) => {
                                            const d = e.target.value
                                            if (!d.trim()) setEndDateTwo(today)
                                            else setEndDateTwo(d)
                                          }}
                                          max={today}
                                        />
                                      </Col>

                                      <Col xs={12} sm={12} md={3} lg={3}>
                                        <div className='form-group'>
                                          <label>Select Bank:</label>
                                          <Select
                                            getOptionLabel={(option) =>
                                              option.bank_name
                                            }
                                            getOptionValue={(option) =>
                                              option.access_token
                                            }
                                            options={banks}
                                            className='new-font-size'
                                            onChange={handleChangeDropdownTwo}
                                            placeholder='Select Bank'
                                            name='selectedBankTwo'
                                            styles={customStyles}
                                            isClearable={true}
                                          />
                                        </div>
                                      </Col>

                                      <Col xs={12} sm={12} md={3} lg={3}>
                                        <div className='form-group'>
                                          <label>Select Account:</label>
                                          <Select
                                            getOptionLabel={(option) =>
                                              `${
                                                option.official_name
                                                  ? option.official_name
                                                  : ''
                                              } ${option.mask}`
                                            }
                                            getOptionValue={(option) =>
                                              option.id
                                            }
                                            options={bankAccountsTwo}
                                            className='new-font-size'
                                            onChange={handleChangeDropdownTwo}
                                            placeholder='Select Bank Account'
                                            name='selectedAccountTwo'
                                            styles={customStyles}
                                            isClearable={true}
                                          />
                                        </div>
                                      </Col>

                                      <Col md={1} lg={1}>
                                        <div style={{ marginTop: '28px' }}>
                                          <button
                                            type='submit'
                                            className='btn btn-info btn-sm'
                                            onClick={(e) => {
                                              paginationOpen.current.dataCall(1)
                                            }}
                                          >
                                            <span className='fa fa-search'></span>{' '}
                                            Search
                                          </button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </React.Fragment>
                              }
                            />
                          </div>
                        )} */}
                      {/* </div>
                    </Col> */}
                  </Row>
                </Grid>
              </div>
            }
          />
        </Row>
      </Grid>
    </div>
  )
}

export default TransactionStatusList
