import React, { useState, useEffect, useRef } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { Card } from 'components/Card/Card.jsx'
import moment from "moment";
import { logServices } from '../../../services/logs'
import { FieldGroup as FieldGroupTwo } from "../../FormInputs/FormInputs";
import { Button } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/actions/alert-actions'
import XLSX from "sheetjs-style"
import { formattedLogData } from './formatlogdata';
// import Pagination from 'components/ThemeComponents/Pagination'
import LogsTable from './LogsTable';


const startDateDefault = moment()
  .subtract(5, "day")
  .format("YYYY-MM-DD");

const Logs = () => {

  const today = moment().format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState(startDateDefault);
  const [endDate, setEndDate] = useState(today);
  const [logsDetails , setLogsDetails] = useState(null)
  const dispatch = useDispatch()
  
  // const pagination = useRef();
  
  const getLogsData =  async (data) => {
    let obj = {
      ...data,
      startDate,
      endDate
    }
    let res = await logServices.getLogs(obj)
    // console.log('res--------', res)
    if(res.data  && res.data.activityLogs) {
      setLogsDetails(res.data.activityLogs)
    }
    return res
  }

  const exportCSV = async (obj) => {
    try {

      let res = await logServices.getLogs(obj)
      if (res && res.status === 'success') {

        let result = res.data && res.data.activityLogs 
        if (result && result.length > 0) {


          const formatData = formattedLogData(result)
          let ws = XLSX.utils.aoa_to_sheet(formatData);
          ws["!merges"] = [XLSX.utils.decode_range("A1:F1")];
          ws["!rows"] = [{ hpt: 20 }];
          ws["A1"].s = {
            font: {
              sz: 16,
              color: {
                rgb: "FF0000",
              },
            },
            fill: {
              fgColor: { rgb: "FFFF00" },
            },
            alignment: {
              horizontal: "center",
              vertical: "center",
            },
          };
          const get_header_row = (sheet) => {
            let range = XLSX.utils.decode_range(sheet["!ref"]);
            let C,
              R = 1;
            let headers = [];
            for (C = range.s.c; C <= range.e.c; ++C) {
              let cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
              var cell_address = { c: C, r: R };
              var cell_ref = XLSX.utils.encode_cell(cell_address);
              if (cell && cell.t) headers.push(cell_ref);
            }
            return headers;
          };
          const h = get_header_row(ws);
          h.map(
            (a) =>
            (ws[a].s = {
              font: {
                bold: true,
              },
              alignment: {
                horizontal: "center",
                vertical: "center",
              },
            })
          );
          ws['!cols'] = [{ width: 25 }, { width: 25 }, { width: 25 }, { width: 25 }, { width: 130 }, { width: 20 }];
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "Log Detail");
          XLSX.writeFile(wb, "LogsDetail.xlsx");


          dispatch(alertActions.success(res.message))
        } else {
          dispatch(alertActions.error("No logs found"));
        }
      }
      else {
        dispatch(alertActions.error(res.message))
      }

    } catch (error) {
      console.log(error);
    }
  };

  // const columns = [
  //   {
  //     Header: "Time",
  //     accessor: "time",
  //     sortable: false,
  //     maxWidth: 200,
  //     Cell: ({ row, value }) => {
  //       return (
  //         <div>
  //           {/* {moment(value).format("LL")}{" "} */}
  //           {moment(row._original.timestamp).format("MMMM Do YYYY, h:mm:ss")}
  //         </div>
  //       );
  //     }
  //   },

  //   // {
  //   //   Header: "Action",
  //   //   accessor: "action",
  //   //   sortable: false,
  //   // },
  //   {
  //     Header: "Module",
  //     accessor: "module",
  //     sortable: false,
  //     maxWidth: 200,
  //   },
  //   {
  //     Header: "User Name",
  //     accessor: "user_name",
  //     sortable: false,
  //     maxWidth: 150,
  //     Cell: ({ row }) => (
  //       <div>
  //         {  row && row._original && row._original.userName}
  //       </div>
  //     ),
  //   },
  //   {
  //     Header: "Description",
  //     accessor: "description",
  //     sortable: false,
  //     Cell: ({ row }) => (
  //       <div>
  //      {/* { console.log('row', row)} */}
  //         {  row && row._original && row._original.changes[0]}
  //       </div>
  //     ),
  //     maxWidth: 600
     
  //   },
    
  // ];

  /*const paginationCall = async (data) => {
    console.log('data', data)
    let dateObj = {
      startDate,
      endDate,
      ...data
    }
     const response = await logServices.getLogs(dateObj);
     return response
  }; */

  useEffect(() => {
    getLogsData()
  }, [startDate , endDate]);

  return (
    <>
      <div>
        <Grid fluid>
          <Row>

            <Card
              ctTableResponsive
              content={
                <>
                  <Grid fluid>
                    <div className='secHeading' style={{ marginLeft: 20 }}>
                      <i
                        className='fa fa-history'
                        aria-hidden='true'
                        style={{ marginRight: 10 }}
                      ></i>
                      {'  '}
                      Activity Logs
                    </div>

                  </Grid>
                </>
              }
            />

            <Col style={{ marginLeft: 20, marginTop: 10 }}>
              <Col xs={12} sm={12} md={2} lg={2}  >
                <span style={{ fontSize: 16, color: '#9999A1' }}> Start Date</span>
                <FieldGroupTwo
                  id='startDate'
                  type='date'
                  name='startDate'
                  value={startDate}
                  className='fieldGroup'
                  onChange={e => {
                    const d = e.target.value
                    if (!d.trim()) setStartDate(startDateDefault)
                    else setStartDate(d)
                  }}
                  max={endDate}
                />
              </Col>

              <Col xs={12} sm={12} md={2} lg={2} >
                <span style={{ fontSize: 16, color: '#9999A1' }}> End Date</span>
                <FieldGroupTwo
                  id='endDate'
                  type='date'
                  name='endDate'
                  value={endDate}
                  className='fieldGroup'
                  onChange={e => {
                    const d = e.target.value
                    if (!d.trim()) setEndDate(today)
                    else setEndDate(d)
                  }}
                  max={today}
                />
              </Col>

              <Col md={1} lg={1} sm={12} xs={12}>
                <div style={{ marginTop: '25px' }}>
                  <Button
                    type='submit'
                    className='btn btn-lg srchBtn'
                    onClick={e => {
                      let dateObj = {
                        startDate,
                        endDate
                      }
                      exportCSV(dateObj)
                    }}
                  >
                    Export Logs
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>

          {logsDetails && logsDetails.length > 0 ?

            <LogsTable logsData={logsDetails} /> : ''
          } 
         
          {/* table view  for logs*/}
          {/* <Pagination
            ref={pagination}
            columns={columns}
            getDataCall={paginationCall}
            // updatedData={logsDetails}
            noDataText="No Item found"
            showPagination={true}
            // page={0}
            pageSize={100}
          filterViewForFilter={true}
          filterPlaceHolder="Search"
          /> */}
          
        </Grid>
      </div>
    </>
  )
}

export default Logs
