import React, { Component } from "react";
import { Switch, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loadProgressBar } from "axios-progress-bar";

import Header from "../../components/Admin/Header/Header";
import Footer from "../../components/Admin/Footer/Footer";
import SidebarStatic from "../../components/Admin/Sidebar/SidebarStatic";
import dashboardRoutes from "routes/dashboard.jsx";
import { AdminAuthRoute } from "../../helper/PrivateRouteAdmin";
import { alertActions } from "redux/actions/alert-actions";
import { showLoader } from "redux/actions/settings-actions";
import MessageCom from "layouts/Dashboard/Message";
import { history } from "../../helper/history";
import ErrorBoundary from "layouts/Dashboard/ErrorBoundary";

import "axios-progress-bar/dist/nprogress.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/animate.min.css";
import "../../assets/css/gen_classes.css";
import "../../assets/sass/light-bootstrap-dashboard.css?v=1.2.0";
import "../../assets/css/demo.css";
import "../../assets/css/pe-icon-7-stroke.css";
import "../../assets/fonts/robotoFont.css";


class DashboardPage extends Component {
  constructor(props) {
    super(props);
    // const { dispatch } = this.props;
    this.props.showLoaderCall(true);
    this.state = {
      canvasMenuToggle: false,
    };
    // dispatch(showLoader(true));
    history.listen((location, action) => {
      this.props.alertActionCall();
    });
    this.closeSideBarMenu = this.closeSideBarMenu.bind(this);
    this.showCanvasMenu = this.showCanvasMenu.bind(this);
  }
  closeSideBarMenu() {
    this.setState({ canvasMenuToggle: false }, function () {
      // $(".sidebar").removeClass("showSideNav");
      document.querySelector('.sidebar').classList.remove('showSideNav')
      // var sub_height = $(".sidebar").outerWidth();
      // $(".main-panel").css({ width: "calc(100% - 50px)" });
      const mainPanel = document.querySelector('.main-panel');
      mainPanel.style.width = "calc(100% - 50px)"
    });
  }
  showCanvasMenu() {
    console.log('called')
    this.setState({ canvasMenuToggle: true }, function () {
      // $(".sidebar").addClass("showSideNav");
      document.querySelector('.sidebar').classList.add('showSideNav')
      setTimeout(function () {
        var sub_height = 0;//$(".sidebar").outerWidth();
        console.log('sub height', sub_height);
        // $(".main-panel").css({ width: "calc(100% - " + sub_height + "px)" });
        const mainPanel = document.querySelector('.main-panel')
        mainPanel.style.width = "calc(100% - " + sub_height + "px)"
      }, 100);
    });
  }

  onMainPanelClick() {
    // $(".Sidebar").removeClass("active");
    document.querySelector('.Sidebar').classList.remove('active')
    document.querySelector('#main-panel').classList.remove('active')
    // $("#main-panel").removeClass("active");
    // var header = $("i.header-menu-bars");
    // var checkClass = $(".header-menu-bars").hasClass("fa-times fa");
    // if (checkClass) {
    //   header.removeClass("fa-times fa", checkClass);
    //   header.addClass("fa fa-bars", checkClass);
    // }
    document.querySelector("#main-panel").classList.remove("active");
    const header = document.querySelector("i.header-menu-bars");
    const checkClass = header && header.classList && header.classList.contains("fa-times") && header.classList.contains("fa");

    if (checkClass) {
      header.classList.remove("fa-times", "fa");
      header.classList.add("fa", "fa-bars");
    }
  }

  render() {
    const { showLoader } = this.props;
    // console.log('showLoader', showLoader);
    return (
      <div className="wrapper">
        <SidebarStatic
          {...this.props}
          hideSideNav={this.closeSideBarMenu}
          showSideNav={this.showCanvasMenu}
        />
        <div>
          <Header {...this.props} ref="child" />
        </div>
        <div
          id="main-panel"
          className="main-panel"
          ref="mainPanel"
          onClick={this.onMainPanelClick}
          style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 76px)' }}
        >
          <div>
            <MessageCom />
            {loadProgressBar({ showSpinner: showLoader })}
          </div>

          <ErrorBoundary>
            <Switch>
              {dashboardRoutes.map((prop, key) => {
                if (prop.redirect) {
                  return <Redirect from={prop.path} to={prop.to} key={key} />;
                }
                /*else if (hasPermission(prop.permission)){*/
                return (
                  <AdminAuthRoute
                    path={prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
                /*}*/
              })}
            </Switch>
          </ErrorBoundary>
          <Footer />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  const { showLoader } = state.settingsReducer;
  const typeOfAlert = typeof alert.message;
  // console.log('showLoader', showLoader);
  return {
    alert,
    typeOfAlert,
    showLoader,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoaderCall: (value) => {
      dispatch(showLoader(value));
    },
    alertActionCall: () => {
      dispatch(alertActions.clear());
    },
  };
};

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);

export default Dashboard;
