import { SC } from '../helper/ServerCall';

 export const giftCardServices = {
    storeGiftCard,
    getGiftCard,
    getCsvGiftCard,
	getGiftCardVendor
  }

function storeGiftCard (data) {
    return SC.postCall(`storeGiftCard`, data)
}

function getGiftCard (filter){
    return SC.postCall(`getGiftCard`,filter)
}

function getCsvGiftCard (){
  return SC.getCall(`csvgetGiftCard`)
}

/**
 * Fetch vendors list
 */
function getGiftCardVendor (obj){
  return SC.postCall(`get-gift-card-vendor`, obj)
}