import React, {  useEffect, useState } from 'react';
import {useDispatch} from 'react-redux';
import { userVerify } from 'redux/actions/admin-user-action';
import MessageCom from 'layouts/Dashboard/Message';
// import MyImage from '../../components/Upload/MyImage';
import { history } from '../../helper/history';
import { alertActions } from "redux/actions/alert-actions";
import { adminUserService } from '../../services/adminUser';







const  UserVerify = () => {
    const dispatch = useDispatch();
   
    const [state, setState] = useState({
    submitted: false,
    email: history.location.state[0].email,
    code:""
   });
   
   
   useEffect(()=>{
      dispatch( alertActions.success(`A 6-digit verification code has been sent at your email address`))
   },[])

    const handleChange = (e) =>{
        const {  value } = e.target;
        setState({...state , code: value });

    }

  const handleSubmit = (e) => {
        e.preventDefault();
        setState({ ...state, submitted: true });
        dispatch(userVerify(state.email,state.code))
        
        
    }

  const handleResend = async () => {
      try{
        let res = await adminUserService.resendCode(state.email);
        if(res.status){
         dispatch( alertActions.success(`A 6-digit verification code has been sent at your email address`))
        }

      }catch(err){
        console.log(err)
      }
   
   
      
  }  
    let {code,submitted} = state;

        return (
            <div className="main_login">
                <MessageCom />
                <p className="text-center logo_container logo-text">
                    Transaction Management
                </p>
                <div className="login_form_container">
                    <div className="login_form">
                        <h1 className="text-center testrrr" >Verify Code</h1>
                        <form name="form" onSubmit={handleSubmit}>
                            <div className={'form-group' + (submitted && !code  ? ' has-error' : '')}>
                                <input type="text" placeholder="Enter your verification code" className="form-control email_ctrl" name="code" value={state.code} onChange={handleChange} />
                                {submitted && !code &&
                                    <div className="help-block">code is required.</div>
                                }
                            </div>
                           
                            <div className="form-group">
                                <button className="btn btn-default btn_login">Submit</button>
                                {/* {
                                    <MyImage alt="imageLogin" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                } */}
                            </div>
                            

                        </form>
                        <div style={{textAlign:'right' }}>
                      &nbsp;
                      <button className="btn btn-default rsndBtn" type="button" onClick={()=> handleResend()}>Resend Code</button>
                      </div>
                    </div>
                </div>
                  
            </div>
        );
    
}

export default UserVerify;